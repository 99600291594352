const Favicon = ({ icon, title }: { icon: string, title:string }) => {
  const favicon: any = document.getElementById('favicon')
  if (favicon) {
    favicon.href = icon
  }
  document.title = title

  return null
}

export default Favicon
