import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import customerManagementStyles from './customerManagementStyles';
import language from "../../language/language";
import { LanguageContext } from "../../utilities/contexts/LanguageContext";
import { useContext, useEffect, useState } from "react";
import CustomerDetailsList from "./CustomerDetailsList/CustomerDetailsList";
import { useCustomerDetailsByStore } from "../../queries/Customer/useCustomerDetailsByStore";
import emptyCustomerResponse, { CustomerAddressResponse, CustomerDetails, CustomerResponse, transformAddressAPIResponse } from "../../utilities/services/CustomerService/CustomerService";
import CustomerDetailsView from "./CustomerDetails/CustomerDetailsView";

const CustomerManagement = () => {
    const { currentLanguage } = useContext(LanguageContext)
    const [arSearchValue, setARSearchValue] = useState('')
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)
    const [customerDetails, setCustomerDetails] = useState<CustomerResponse>();
    const {data: customerDetailsResp, refetch: searchCustomerDetails, error: errorCustomersQuery} = useCustomerDetailsByStore(arSearchValue, page, pageSize);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails | null>()
    
    useEffect(()=>{
        searchCustomerDetails()
    },[page, pageSize, searchCustomerDetails])

    useEffect(()=>{
        setCustomerDetails(customerDetailsResp)
    }, [customerDetailsResp])

    useEffect(()=>{
        if (!!selectedCustomer) {
            setSelectedCustomer(customerDetails?.commercialCustomerListInfo.find(customerDetails=>{
                return customerDetails.customerInfo.customerId === selectedCustomer.customerInfo.customerId
            }))
        }
    }, [customerDetails, selectedCustomer])

    return (
        <>
        {!!selectedCustomer ? 
            <CustomerDetailsView 
                customerDetails={selectedCustomer} 
                onBackPageClick={()=>setSelectedCustomer(null)}
                onCustomerUpdate={(updatedAddress: CustomerAddressResponse)=>{
                    //updating customer address state with updated address 
                    customerDetails?.commercialCustomerListInfo.forEach(customer=>{
                        if (customer.customerInfo.customerId === updatedAddress.customerId) {
                            if (!customer.addressInfo.find(address=>address.addressId === updatedAddress.addressId)) {
                                customer.addressInfo.push(transformAddressAPIResponse(updatedAddress))
                            } else {
                                customer.addressInfo = customer.addressInfo.map(address=>{
                                    if (address.addressId === updatedAddress.addressId) {
                                        return transformAddressAPIResponse(updatedAddress);
                                    }
                                    return address;
                                })
                            }
                            setSelectedCustomer(customer)
                        }
                    })
                    setCustomerDetails(customerDetails)
                }}
            /> : <></>
        }
        <Grid container spacing={3} padding={2} display={selectedCustomer ? 'none' : ''}>
            <Grid item container xs={12} style={customerManagementStyles.gridContainer}>
                <Grid>
                    <Typography style={customerManagementStyles.header}>{(language as any)[currentLanguage].customerMgmt}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3}>
            <Box display="flex" flexDirection="column" gap={2} style={customerManagementStyles.searchContainer}>
                <Box display="flex" flexDirection="column" gap="8px">
                    <Typography style={customerManagementStyles.sectionHeader} align="left">
                        {(language as any)[currentLanguage].customerMgmtSearchHeader}
                    </Typography>
                    <Typography style={customerManagementStyles.sectionContent} align="left">
                        {(language as any)[currentLanguage].customerMgmtSearchSubText}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                    <Typography style={customerManagementStyles.sectionInputHeader} align="left">
                        {
                            (language as any)[currentLanguage].arNumber
                        } ({(language as any)[currentLanguage].required}):</Typography>
                    <TextField
                        data-testid="ar_number_input"
                        label="ex: 1234567"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={arSearchValue}
                        onChange={(event)=>setARSearchValue(event.target.value)}
                    />
                </Box>
                
                <Button variant="contained" color="primary" fullWidth sx={{height: '50px'}} 
                    data-testid="ar_number_search"
                    disabled={arSearchValue ? false : true}
                    onClick={() => searchCustomerDetails()}
                >
                    {(language as any)[currentLanguage].search}
                </Button>
            </Box>
            </Grid>
            <Grid item xs={9}>
                <CustomerDetailsList
                    customerDetails={errorCustomersQuery ? emptyCustomerResponse() : customerDetails as CustomerResponse}
                    handlePageChange={(event)=>{setPage(event+1)}}
                    handleRowsPerPageChange={(event)=>{setPageSize(event)}}
                    onCustomerDetailsClick={(event: CustomerDetails) => {
                        setSelectedCustomer(event)
                    }}
                ></CustomerDetailsList>
            </Grid>
        </Grid>
        </>
    )
}

export default CustomerManagement;