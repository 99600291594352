import { useContext } from 'react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { useQuery } from 'react-query'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { CustomerService } from '../../utilities/services/CustomerService/CustomerService'

export const useCustomerDetailsByStore = (
  arNumber: any,
  page: number,
  pageSize: number
) => {
  const { currentStore } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { addSnack } = useContext(SnackbarContext)
  return useQuery(
    [currentStore, arNumber, page, pageSize],
    (context) =>
      CustomerService.getCustomerDetails(
        context.queryKey[0],
        context.queryKey[1],
        context.queryKey[2],
        context.queryKey[3]
      ),
    {
      enabled: false,
      onError: (err: any) => {
        if (err.status !== 'NOT_FOUND')
          addSnack({
            severity: 'error',
            message: (language as any)[currentLanguage].errorCustomerDetails,
            duration: 3000
          })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
    }
  )
}
