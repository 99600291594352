import axios from 'axios'
import { AuthHelper } from '../helpers/AuthHelper'

export class FeatureConfigurationService {
    static FEATURE_CONFIGURATION_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/configuration'

    static async getFeatureConfiguration(): Promise<any> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens()
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        }
        try {
            const response = await axios.get<any>(
                `${this.FEATURE_CONFIGURATION_ENDPOINT}`,
                config
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
