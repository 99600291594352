import { Navigate, Route, Routes } from 'react-router-dom'
import Whiteboard from '../../screens/Whiteboard/Whiteboard'
import RouteBuilder from '../../screens/RouteBuilder/RouteBuilder'
import UserManagement from '../../screens/UserManagement/UserManagement'
import Reports from '../../screens/Reports/Reports'
import MyFleet from '../../screens/MyFleet/Myfleet'
import DigitalReturns from '../../screens/DigitalReturns/DigitalReturns'
import VehicleDetails from '../../screens/MyFleet/components/VehicleDetails/VehicleDetails'
import { useFlags } from 'launchdarkly-react-client-sdk'
import CustomerManagement from '../../screens/CustomerManagement/CustomerManagement'

const NavBarMenu = () => {
  const { isReturnsEnabled } = useFlags()

  return (
    <Routes>
      <Route index element={<Navigate to="/Whiteboard" />} />
      <Route path="/Whiteboard" element={<Whiteboard />} />
      <Route path="/CustomerMgmt" element={<CustomerManagement />} />
      <Route path="/RouteBuilder" element={<RouteBuilder />} />
      <Route path="/UserManagement" element={<UserManagement />} />
      <Route path="/Reports" element={<Reports />} />
      <Route path="/MyFleet" element={<MyFleet />} />
      {isReturnsEnabled && <Route path="/DigitalReturns" element={<DigitalReturns />} />}
      <Route path="/VehicleDetails" element={<VehicleDetails />} />
      <Route path="*" element={<p>Page Not Found</p>} />
    </Routes>
  )
}

export default NavBarMenu
