import { Box, Button, Checkbox, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { AddressInfo, americaStateList, CustomerAddressPayload, toCustomerAddressPayload, CustomerInfo, formatLatLang, validateLatitude, validateLongitude } from "../../../../utilities/services/CustomerService/CustomerService"
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import { ReactComponent as DropOffPin } from '../../../../assets/drop_off_pin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { customerDetailsStyles } from "../CustomerDetailsStyles"
import Colors from "../../../../assets/Colors"
import { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../../../../utilities/contexts/LanguageContext"
import language from "../../../../language/language"

interface InputProps {
    customerInfo: CustomerInfo,
    addressInfo: AddressInfo,
    onSave: (addressUpdate: CustomerAddressPayload) => void
    onCancel: () => void,
    lngLat: [number, number] | null
    setLngLat: (value: [number, number] | null) => void,
    index: number
}

const CustomerAddress = ({ customerInfo, addressInfo, onSave, onCancel, lngLat, setLngLat, index}: InputProps) => {
    const [sameLocationDrop, setSameLocationDrop] = useState<boolean>(true);
    const { currentLanguage } = useContext(LanguageContext)

    const [formValues, setFormValues] = useState<CustomerAddressPayload>(toCustomerAddressPayload(
        customerInfo.customerId, addressInfo
    ))
    const [isFormValid, setFormValid] = useState<boolean>();

    useEffect(()=>{
        const existingLngLat = [
            +addressInfo?.deliveryGeocodes?.[0].longitude,
            +addressInfo?.deliveryGeocodes?.[0].latitude
        ]
        //reset pin label on drop pin change
        const resetPinLabel = lngLat && JSON.stringify(existingLngLat) !== JSON.stringify(lngLat)
        setFormValues(state => ({
            ...state,
            deliveryGeocodes: [
                {
                    ...state.deliveryGeocodes[0],
                    pinLabel: resetPinLabel ? '' : addressInfo?.deliveryGeocodes?.[0]?.pinLabel
                }
            ]
        }))
    }, [lngLat, addressInfo])

    const handleChange = (event: {target: any}) => {
        setFormValues({
            ...formValues,
            [event.target?.name]: event.target?.value
        })
    }

    const handleDeliveryChange = (event: {target: any}) => {
        setFormValues({
            ...formValues,
            deliveryGeocodes: [
                {...formValues.deliveryGeocodes[0],
                    [event.target?.name]: event.target?.value
                }
            ]
        })
    }

    useEffect(()=>{
        const validateForm = () =>{
            const requiredFields = ['line1', 'city', 'state', 'zip', 'country']
            let isValid = true;
            Object.keys(formValues)
                    .filter(key => requiredFields.indexOf(key) !== -1)
                    .forEach((key) => {
                        if (isValid) isValid = (formValues as any)[key] !== ''
                    })
            if (isValid && !sameLocationDrop && formValues.deliveryGeocodes[0].pinLabel === '') isValid = false
            setFormValid(isValid)
        }
        validateForm()
        setSameLocationDrop(formValues.deliveryGeocodes[0]?.isDefault === 'Y' ? true : false)
    }, [formValues, sameLocationDrop])

    useEffect(()=>{
        if (sameLocationDrop) {
            setLngLat(null)
        } else {
            setLngLat([
                validateLongitude(+addressInfo?.deliveryGeocodes?.[0]?.longitude),
                validateLatitude(+addressInfo?.deliveryGeocodes?.[0]?.latitude)
            ])
        }
    },[sameLocationDrop, addressInfo, setLngLat])

    return <Box display="flex" flexDirection="column" gap={"16px"} alignItems="start">
        <Typography sx={customerDetailsStyles.typoHeader5}>
        {(language as any)[currentLanguage].location} {index + 1}: {customerInfo?.name} - {index + 1}
        </Typography>
        <Box>

            <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                <PinIcon />
                {(language as any)[currentLanguage].address}
            </Typography>
            <Box sx={{ paddingLeft: '20px' }}>
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].locationName}
                    </Typography>
                    <OutlinedInput placeholder="ex: business - site #2" size="small" fullWidth
                        name="locationName"
                        // value={formValues.}
                        onChange={handleChange}></OutlinedInput>
                </Box>
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].addressLine} 1
                    </Typography>
                    <TextField size="small" fullWidth 
                        name="line1"
                        value={formValues.line1}
                        onChange={handleChange}></TextField>
                    <Typography sx={customerDetailsStyles.small}>
                        {(language as any)[currentLanguage].optionalAddress}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between" gap="16px" sx={{ textAlign: 'left' }}>
                    <Box>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].city}
                        </Typography>
                        <TextField size="small"
                            name="city"
                            value={formValues.city}
                            onChange={handleChange}></TextField>
                    </Box>
                    <Box sx={{ width: '100px' }}>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].state}
                        </Typography>
                        <Select sx={{ width: '100%' }} size="small"
                            name={"state"}
                            value={formValues.state}
                            onChange={handleChange}>
                            {americaStateList.map((state)=>{
                                return <MenuItem value={state.value}>{state.label}</MenuItem>
                            })}
                        </Select>
                    </Box>
                    <Box sx={{ width: '150px' }}>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].zipCode}
                        </Typography>
                        <TextField size="small"
                            name={"zip"}
                            value={formValues.zip}
                            onChange={handleChange}></TextField>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox sx={{ paddingLeft: '0px' }} checked={sameLocationDrop} onChange={(event)=>{
                    setSameLocationDrop(event.target.checked)
                    formValues.deliveryGeocodes[0].isDefault = event.target.checked ? 'Y' : 'N'
                }}></Checkbox>
                <Typography sx={customerDetailsStyles.paragraph2}>
                    {(language as any)[currentLanguage].dropOffSameLocation}
                </Typography>
            </Box>
            {!sameLocationDrop && <Box display="flex" flexDirection="column" paddingLeft="20px" gap="8px">
                <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                    <DropOffPin />
                    {(language as any)[currentLanguage].dropOffSpot}
                </Typography>
                <Box display="flex" flexDirection="column" paddingLeft="20px" gap="8px">
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].dropPinMapDesc}
                    </Typography>
                    <Box display="flex" flexDirection="row">
                        <Typography sx={customerDetailsStyles.paragraphBold2}>
                            {(language as any)[currentLanguage].coordinates}
                        </Typography>
                        <Typography sx={customerDetailsStyles.paragraph2}>{formatLatLang(lngLat?.[1] || 180, lngLat?.[0] || 90)}</Typography>
                    </Box>
                    <Box width="100%">
                        <Typography sx={customerDetailsStyles.paragraphBold2}>
                            {(language as any)[currentLanguage].coordinates} (required)
                        </Typography>
                        <OutlinedInput fullWidth size="small" placeholder="ex: back of store"
                            name={'pinLabel'}
                            value={formValues.deliveryGeocodes[0].pinLabel}
                            onChange={handleDeliveryChange}></OutlinedInput>
                    </Box>
                </Box>
            </Box>}
        </Box>

        <Box width="100%">
            <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                <NotesIcon />
                {(language as any)[currentLanguage].deliveryNotes}
            </Typography>
            <Box sx={{ paddingLeft: '20px' }}>
                <OutlinedInput fullWidth size="small" placeholder="ex: all deliveries at back entrance"
                    name={'notes'}
                    value={formValues.notes}
                    onChange={handleChange}></OutlinedInput>
            </Box>
        </Box>
        <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between">
            <Button size="small" variant="contained" sx={{ background: Colors.napaRed4 }}
                disabled={!addressInfo.addressId}>
                {(language as any)[currentLanguage].delete} {(language as any)[currentLanguage].location}
            </Button>
            <Box display={"flex"} gap={"16px"}>
                <Button size="small" variant="outlined" onClick={onCancel}>
                    {(language as any)[currentLanguage].cancel}
                </Button>
                <Button sx={{width: 'inherit'}} size="small" variant="primary" onClick={()=>{
                        if (!sameLocationDrop && lngLat != null) {
                            formValues.deliveryGeocodes[0].latitude = lngLat?.[1].toFixed(6)+""
                            formValues.deliveryGeocodes[0].longitude = lngLat?.[0].toFixed(6)+""
                        }
                        onSave(formValues)
                    }} disabled={!isFormValid}>
                    {(language as any)[currentLanguage].save}
                </Button>
            </Box>
        </Box>
    </Box>
}

export default CustomerAddress