import Colors from "../../../assets/Colors";

export const customerDetailsStyles = {
    typoSmall: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19.5px',
        textAlign: 'center',
        color: Colors.napaGrey2
    },
    typoHeader3 : {
        //styleName: H3;
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaBlue
    },
    typoHeader5: {
        //styleName: H5;
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.32px',
        textAlign: 'left',
        color: Colors.napaGrey2
    },
    paragraphBold2: {
        //styleName: Paragraph 2 Bold;
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0.36px',
        textAlign: 'left',
        color: Colors.napaGrey2
    },
    paragraph2: {
        //styleName: Paragraph 2;
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaGrey2
    },
    paragraph2Link : {
        //styleName: Paragraph 2;
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaBlueLink,
        textTransform: 'none'
    },
    accountDetailsLayout: {
        margin: '0px',
        border: '1px solid #F4F4F4',
        borderRadius: '5px'
    },
    serviceLevel: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center'

    },
    small : {
        //styleName: Small;
        fontSize: '12px',
        fontWeight: '400',
        lineeHight: '15px',
        letterSpacing: '0.42px',
        textAlign: 'center',
        color: Colors.napaBlueLink
    }
}