import React, { useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { Box } from '@mui/system'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import language from '../../../../language/language'
import Colors from '../../../../assets/Colors'
import { emptyBuildRouteStyles } from './EmptyBuildRouteStyles'
import emptyStop from '../../../../assets/edit-stop.png'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { useUsersByStore } from '../../../../queries/Users/useUsersByStore'

type EmptyBuildRouteProps = {
    selectedDriverId: string
    setSelectedDriverId: (selectedDriverId: string) => void
    openEmptyModal: boolean
    setOpenEmptyModal: (value: boolean) => void
    setOpenAddInvoices: (value: boolean) => void
    setOpenCustomStop: (value: boolean) => void
}

const EmptyBuildRoute = ({
    selectedDriverId,
    setSelectedDriverId,
    openEmptyModal,
    setOpenEmptyModal,
    setOpenAddInvoices,
    setOpenCustomStop
}: EmptyBuildRouteProps) => {
    const { data: drivers } = useUsersByStore()
    const { storeAddress } = useContext(StoreContext)
    const { currentLanguage } = useContext(LanguageContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenu = () => {
        setAnchorEl(null)
    }

    const handleChangeDriver = (e: { target: { value: string } }) => {
        setSelectedDriverId(e.target.value)
    }

    const handleClose = () => {
        setOpenEmptyModal(false)
    }
   
    const handleAddInvoice =() => {
        setOpenAddInvoices(true)
        handleMenu()
    }

    const handleCustomStop = () => {
        setOpenCustomStop(true)
        handleMenu()
    }

    const selectDriver = () => {
        return (
            <Grid item xs={12} sx={[emptyBuildRouteStyles.driversFormGrid, { paddingLeft: 0 }]}>
                <FormControl sx={emptyBuildRouteStyles.driversForm}>
                    <Typography sx={emptyBuildRouteStyles.driverType}>
                        {language[currentLanguage].driver}*
                    </Typography>
                    <Select
                        id='drivers_list_invoice'
                        inputProps={{ 'data-testid': 'user-type-select' }}
                        displayEmpty
                        value={selectedDriverId}
                        required={true}
                        onChange={handleChangeDriver}
                        placeholder={language[currentLanguage].selectType}
                        style={{ maxHeight: '40px' }}>
                        <MenuItem value='None'>
                            <em>{language[currentLanguage].none}</em>
                        </MenuItem>
                        {drivers &&
                            drivers?.map((driver) => {
                                return (
                                    <MenuItem key={driver.username} value={driver.username}>
                                        <em>
                                            {driver.givenName} {driver.familyName}
                                        </em>
                                    </MenuItem>
                                )
                            })}
                    </Select>
                </FormControl>
            </Grid>
        )
    }

    return (
        <Dialog open={openEmptyModal} fullWidth={true} onClose={handleClose}>
            <Grid container sx={emptyBuildRouteStyles.marginT10}>
                <Grid item xs={11}>
                    <Typography sx={emptyBuildRouteStyles.buildRouteText}>
                        {language[currentLanguage].buildRouteText}
                    </Typography>
                </Grid>
                <Grid item xs={1} textAlign={'right'}>
                    <IconButton onClick={handleClose}>
                        <Clear fontSize='medium' />
                    </IconButton>
                </Grid>
            </Grid>
            <Typography sx={emptyBuildRouteStyles.driverText}>
                {language[currentLanguage].driver}:
            </Typography>
            <Grid container sx={emptyBuildRouteStyles.paddingRL}>
                <Grid item xs={10}>
                    <Typography fontSize={'14px'} >
                        {language[currentLanguage].totalStops}{' '} 0
                    </Typography>
                </Grid>
                <Grid item xs={2} textAlign={'right'}>
                    <>
                        <Button
                            variant='primary'
                            onClick={handleClick}
                            sx={emptyBuildRouteStyles.addStop}
                        >
                            {language[currentLanguage].addStop}
                            <KeyboardArrowDownSharpIcon sx={emptyBuildRouteStyles.downIcon} />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenu}
                            style={emptyBuildRouteStyles.marginT5}
                        >
                            <MenuItem onClick={handleAddInvoice} sx={emptyBuildRouteStyles.invoiceText} style={{paddingTop: '0px'}}>
                                <AddOutlinedIcon fontSize='small' sx={emptyBuildRouteStyles.marginR5} />
                                {language[currentLanguage].addInvoice}
                            </MenuItem>
                            <MenuItem onClick={handleCustomStop} sx={emptyBuildRouteStyles.invoiceText} style={{paddingBottom: '0px'}}>
                                <EditOutlinedIcon fontSize='small' sx={emptyBuildRouteStyles.marginR5} />
                                {language[currentLanguage].customStop}
                            </MenuItem>
                        </Menu>
                    </>
                </Grid>
            </Grid>
            <DialogTitle style={emptyBuildRouteStyles.dottedContainer}>
                <Box textAlign={'center'}>
                    <img src={emptyStop} alt='' style={emptyBuildRouteStyles.imgIcon} />
                </Box>
                <Box textAlign={'center'}>
                    <Typography color={Colors.napaGrey}>
                        {language[currentLanguage].noCustomStop}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={emptyBuildRouteStyles.backToStore}>
                <Box sx={emptyBuildRouteStyles.returnStore}>
                    <Typography sx={emptyBuildRouteStyles.invoiceDeliveryAddress}>
                        {language[currentLanguage].returnToStore}
                    </Typography>
                    <DialogContentText sx={emptyBuildRouteStyles.invoiceAddressLineType}>
                        {storeAddress?.addressLine1}, {storeAddress?.addressLine2 ?? ''}{' '}
                        {storeAddress?.city}, {storeAddress?.state} {storeAddress?.zipCode}
                    </DialogContentText>
                </Box>
            </DialogContent>
            <Box sx={emptyBuildRouteStyles.buttonContainer}>
                {selectDriver()}
                <DialogActions sx={emptyBuildRouteStyles.dialogActions}>
                    <>
                        <Button
                            variant='secondary'
                            onClick={() => {
                                setOpenEmptyModal(false)
                            }}
                            fullWidth
                        >
                            {language[currentLanguage].close}
                        </Button>
                        <Button
                            variant='primary'
                            disabled
                            onClick={() => {
                                //TODO
                            }}
                            fullWidth
                        >
                            {language[currentLanguage].buildRouteText}
                        </Button>
                    </>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default EmptyBuildRoute
