import { createContext, useState, useEffect, ReactElement, useContext } from 'react'
import { AuthHelper, getApacUserType } from '../helpers/AuthHelper'
import { useNavigate } from 'react-router-dom'
import { useUserAcceptedTerms } from '../../queries/Terms/useUserAcceptedTerms'
import { IAuthContext, AuthContext as ApacAuthContext } from 'react-oauth2-code-pkce'
import { isApac } from '../helpers/RegionHelper'
import { getApacUserByUserName, UserDataTypes } from '../services/ApacUserService/ApacUserService'
import { SnackbarContext } from './SnackbarContext'
import { LanguageContext } from './LanguageContext'
import language from '../../language/language'
import { UserManagementService } from '../services/UserManagementService'

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isInitializingCurrentUser: true,
  isInitializingTerms: true,
  signIn: () => {},
  signOut: () => {},
  termsAccepted: undefined,
  refetchTerms: () => {}
})

type AuthContextType = {
  currentUser: CurrentUser | null
  isInitializingCurrentUser: boolean
  signIn: (username: string, password: string) => void
  signOut: () => void
  termsAccepted: boolean | undefined
  isInitializingTerms: boolean
  refetchTerms: () => void
}

export interface CurrentUser {
  username: string
  givenName: string
  familyName: string
  email: string
  defaultStoreNumber: string
  storeList: string[]
  userType: string
}

export const AuthProvider = ({ children }: any): ReactElement => {
  let navigate = useNavigate()

  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)
  const [isInitializingCurrentUser, setIsInitializingCurrentUser] = useState<boolean>(true)
  const { token, logOut, loginInProgress, logIn } = useContext<IAuthContext>(ApacAuthContext)
  const {
    data: termsAccepted,
    isLoading: isLoadingTerms,
    refetch: refetchTerms
  } = useUserAcceptedTerms(currentUser?.username as string)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)

  // Compare user details with ping and update the database accordingly
  const fetchAndCompareUserData = async () => {
    try {
      const pingUserData = await AuthHelper.currentSignedInApacUser(token)
      try {
        const userFromDB: UserDataTypes = await getApacUserByUserName(pingUserData.username)
        const currentUser = {
          username: pingUserData.username,
          givenName: pingUserData.given_name,
          familyName: pingUserData.family_name,
          email: pingUserData.UserPrincipalName,
          defaultStoreNumber: userFromDB.defaultStoreNumber,
          userType: getApacUserType(pingUserData),
          storeList: userFromDB.storeList
        }
        // if user disabled then throw error otherwise set the user
        if (!userFromDB.enabled) {
          addSnack({
            severity: 'error',
            message: 'This account has been disabled. Please see store admin to re-enable it',
            duration: 3000
          })
          return Promise.reject()
        }
        setCurrentUser(currentUser)
        // It will call the user update endpoint if data mismatched with ping
        const { payload, isUpdate } = AuthHelper.compareApacUser(pingUserData, userFromDB)
        if (isUpdate) {
          try {
            await UserManagementService.updateUser(payload, pingUserData.username)
          } catch (e) {
            console.error('Unable to update user', e)
          }
        }
      } catch (error: any) {
        addSnack({
          severity: 'error',
          message: error.response.status === 404 ? (language as any)[currentLanguage].errorUserByUserName : error.message,
          duration: 3000
        })
      }
    } catch (e: any) {
      addSnack({
        severity: 'error',
        message: e.message,
        duration: 3000
      })
    } finally {
      setIsInitializingCurrentUser(false)
    }
  }

  useEffect(() => {
    if (isApac) {
      if (!loginInProgress && !token) logIn()
      if (!loginInProgress && token) {
        fetchAndCompareUserData()
      }
    } else {
      AuthHelper.currentSignedInUser()
        .then((user) => {
          setCurrentUser(user)
        })
        .catch((e) => console.error(e.message))
        .finally(() => setIsInitializingCurrentUser(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, loginInProgress])

  const signIn = async (username: string, password: string) => {
    try {
      const currentUser: CurrentUser = await AuthHelper.signIn({ username, password })
      setCurrentUser(currentUser)
      navigate('/')
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  const signOut = async () => {
    if (isApac) {
      setCurrentUser(null)
      logOut()
    } else {
      try {
        await AuthHelper.signOut()
      } catch (error: any) {
        console.error('Error signing out')
      } finally {
        setCurrentUser(null)
        navigate('/')
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isInitializingCurrentUser,
        isInitializingTerms: isLoadingTerms,
        signOut,
        signIn,
        termsAccepted,
        refetchTerms
      }}>
      {children}
    </AuthContext.Provider>
  )
}
