const Colors = {
  white: '#fff',
  black: '#000',
  napaBlack: '#212121',
  napaGreenDark: '#347F51',
  napaGreenLight: '#E4FCEA',
  napaBlue3: '#002788',
  napaBlue: '#001489',
  napaBlue2: '#CCD0E7',
  napaBlue6: '#6672B8',
  napaBlueLink: '#0066CB',
  napaGreyDisabled: '#F4F4F4',
  napaGreyDisabled2: '#ADADAD',
  napaGrey: '#707070',
  napaGrey2: '#333333',
  napaGrey3: '#474747',
  napaGrey4: '#565656',
  napaYellow: '#FFC72C',
  napaYellow1: '#FFEEBF',
  napaYellow3: '#E49600',
  napaRed: '#EC161F',
  napaRed2: '#F5B8BB',
  napaRed3: '#A0171E',
  napaRed4: '#E54D2E',
  warningIconColor: '#FFC72C',
  warningBgColor: '#FFF9E9',
  errorBgColor: '#A0171E',
  warningTextColor: '#333333'
}

export default Colors
