import axios from "axios";
import { AuthHelper } from "../../helpers/AuthHelper";

const STORE_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL

export type CustomerLookupPayload = {
    storeNumber: string,
    accountReceivableNumber: string
}

export type CustomerAddressPayload = {
    customerId: string,
    addressId?: string,
    addressType: string,
    line1: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    notes: string,
    deliveryGeocodes: [
        {
            pinId?: string,
            pinLabel: string,
            latitude: string,
            longitude: string,
            isDefault: string
        }
    ]
}

export type CustomerAddressResponse = CustomerAddressPayload

export class CustomerService {
    static async getCustomerDetails(storeNumber: string, accountReceivableNumber: string, page=1, limit=25) : Promise<CustomerResponse> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens();
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        };
        const searchPayload: CustomerLookupPayload = {
            storeNumber: storeNumber,
            accountReceivableNumber: accountReceivableNumber
        }
        try {
            //sort by will be static to name as backend is not supporting.
            const response = await axios.post<CustomerResponse>(`${STORE_ENDPOINT}/customer/commercial/lookup?page=${page}&limit=${limit}&sortOrder=asc&sortBy=name`, searchPayload, config);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return {
                commercialCustomerListInfo: [],
                message: '',
                pageDetailsInfo: {} as PageDetailsInfo
            } as CustomerResponse
        }
    }


    static async updateCustomer(updatePayload: CustomerAddressPayload) : Promise<CustomerAddressResponse> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens();
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        };
        try {
            const response = await axios.patch<CustomerAddressResponse>(`${STORE_ENDPOINT}/customer/commercial/${updatePayload.customerId}/addresses/${updatePayload.addressId}`, updatePayload, config);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return {
            
            } as CustomerAddressResponse
        }
    }

    static async saveCustomer(updatePayload: CustomerAddressPayload) : Promise<CustomerAddressResponse> {
        const userAuthTokens = await AuthHelper.getUserAuthTokens();
        const config = {
            headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
        };
        try {
            const response = await axios.post<CustomerAddressResponse>(`${STORE_ENDPOINT}/customer/commercial/${updatePayload.customerId}/addresses`, updatePayload, config);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return {
            
            } as CustomerAddressResponse
        }
    }
}

export const transformAddressAPIResponse = (addressResponse: CustomerAddressResponse): AddressInfo => {
    return {
        addressId: addressResponse.addressId,
        addressType: addressResponse.addressType,
        addressline1: addressResponse.line1,
        city: addressResponse.city,
        state: addressResponse.state,
        zip: addressResponse.zip,
        country: addressResponse.country,
        deliveryGeocodes: addressResponse.deliveryGeocodes as DeliveryGeoCodes[],
        note: addressResponse.notes
    } as AddressInfo
}

export type CustomerResponse = {
    commercialCustomerListInfo: CustomerDetails[],
    pageDetailsInfo: PageDetailsInfo,
    message: string
}

export default function emptyCustomerResponse() {
    return {
        
    } as CustomerResponse
}

export type CustomerInfo = {
    customerId: string,
    name: string,
    accountInfo: AccountInfo[],
    contactInfo: ContactInfo[]

}

export type AccountInfo = {
    accountReceivableNumber: string[]
}

export type ContactInfo = {
    contactType: string,
    phoneNumber: string[]
}

export type GeoCode = {
    latitude: string,
    longitude: string
}

export type DeliveryGeoCodes = {
    latitude: string,
    longitude: string,
    pinId: string,
    pinLabel: string,
    isDefault: string
}

export type AddressInfo = {
    addressId: string,
    addressType: string,
    addressline1: string,
    addressline2: string,
    addressline3: string,
    city: string,
    state: string,
    zip: string,
    zipExt: string,
    country: string,
    geocodes: GeoCode,
    deliveryGeocodes: DeliveryGeoCodes[],
    note: string
}

export type Preferences = {
    priorityPlus: boolean
}

export type PageDetailsInfo = {
    currentPage: number,
    nextPage: number,
    prevPage: number,
    pageSize: number,
    totalPages: number,
    totalRecords: number
}

export type CustomerDetails = {
    customerInfo: CustomerInfo,
    addressInfo: AddressInfo[],
    preferences: Preferences
}

export const toCustomerAddressPayload = (customerId: string, addressInfo: AddressInfo): CustomerAddressPayload => {
    const deliveryGeocodes = addressInfo?.deliveryGeocodes?.[0]
    const payload = {
        customerId: customerId,
        addressType: addressInfo?.addressType || 'Delivery',
        line1: addressInfo?.addressline1 || '',
        city: addressInfo?.city || '',
        state: addressInfo?.state || '',
        zip: addressInfo?.zip || '',
        country: addressInfo?.country || 'USA',
        deliveryGeocodes: [
            {
                pinLabel: deliveryGeocodes?.pinLabel || '',
                latitude: deliveryGeocodes?.latitude || '',
                longitude: deliveryGeocodes?.longitude || '',
                isDefault: deliveryGeocodes?.isDefault || 'Y'
            }
        ]
    } as CustomerAddressPayload
    if (addressInfo?.addressId) payload.addressId = addressInfo?.addressId
    if (deliveryGeocodes?.pinId) payload.deliveryGeocodes[0].pinId = deliveryGeocodes?.pinId
    return payload
}

export const formatPhoneNumber = (phone: string):string => {
    return `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6)}`
}

export const formatARNumber = (arNumber: string):string => {
    return `#${arNumber}`
}

const formatDMS = (degress:number, minutes:number, seconds:number): string => {
    return `${Math.round(degress)}°${Math.round(minutes)}'${Math.round(seconds)}"`;
}
export const formatLatLang = (lat:number, long:number): string => {
    return `(${formatDMS(Math.floor(lat), (lat % 1) * 60, (lat % 1 * 60 % 1) * 60)}N, ${formatDMS(Math.floor(long), (long % 1) * 60, (long % 1 * 60 % 1) * 60)}W)`
}

export const americaStateList = [
    { value: "AL", label: "AL - Alabama" },
    { value: "AK", label: "AK - Alaska" },
    { value: "AZ", label: "AZ - Arizona" },
    { value: "AR", label: "AR - Arkansas" },
    { value: "CA", label: "CA - California" },
    { value: "CO", label: "CO - Colorado" },
    { value: "CT", label: "CT - Connecticut" },
    { value: "DE", label: "DE - Delaware" },
    { value: "DC", label: "DC - District Of Columbia" },
    { value: "FL", label: "FL - Florida" },
    { value: "GA", label: "GA - Georgia" },
    { value: "HI", label: "HI - Hawaii" },
    { value: "ID", label: "ID - Idaho" },
    { value: "IL", label: "IL - Illinois" },
    { value: "IN", label: "IN - Indiana" },
    { value: "IA", label: "IA - Iowa" },
    { value: "KS", label: "KS - Kansas" },
    { value: "KY", label: "KY - Kentucky" },
    { value: "LA", label: "LA - Louisiana" },
    { value: "ME", label: "ME - Maine" },
    { value: "MD", label: "MD - Maryland" },
    { value: "MA", label: "MA - Massachusetts" },
    { value: "MI", label: "MI - Michigan" },
    { value: "MN", label: "MN - Minnesota" },
    { value: "MS", label: "MS - Mississippi" },
    { value: "MO", label: "MO - Missouri" },
    { value: "MT", label: "MT - Montana" },
    { value: "NE", label: "NE - Nebraska" },
    { value: "NV", label: "NV - Nevada" },
    { value: "NH", label: "NH - New Hampshire" },
    { value: "NJ", label: "NJ - New Jersey" },
    { value: "NM", label: "NM - New Mexico" },
    { value: "NY", label: "NY - New York" },
    { value: "NC", label: "NC - North Carolina" },
    { value: "ND", label: "ND - North Dakota" },
    { value: "OH", label: "OH - Ohio" },
    { value: "OK", label: "OK - Oklahoma" },
    { value: "OR", label: "OR - Oregon" },
    { value: "PA", label: "PA - Pennsylvania" },
    { value: "PR", label: "PR - Puerto Rico" },
    { value: "RI", label: "RI - Rhode Island" },
    { value: "SC", label: "SC - South Carolina" },
    { value: "SD", label: "SD - South Dakota" },
    { value: "TN", label: "TN - Tennessee" },
    { value: "TX", label: "TX - Texas" },
    { value: "UT", label: "UT - Utah" },
    { value: "VT", label: "VT - Vermont" },
    { value: "VI", label: "VI - Virgin Islands" },
    { value: "VA", label: "VA - Virginia" },
    { value: "WA", label: "WA - Washington" },
    { value: "WV", label: "WV - West Virginia" },
    { value: "WI", label: "WI - Wisconsin" },
    { value: "WY", label: "WY - Wyoming" }
  ];

export const validateLatitude = (lat: number): number => {
    if (isNaN(lat)) return 38;
    return (-90 <= lat && lat <= 90) ? lat : 38
}

export const validateLongitude = (lng: number): number => {
    if (isNaN(lng)) return 106;
    return (-180 <= lng && lng <= 180) ? lng : 106
}