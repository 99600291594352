import Colors from '../../assets/Colors'

const styles = {
  gridContainer: {
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center' as const,
    marginBottom: '8px'
  },
  header: {
    lineHeight: '38px',
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '32px',
    letterSpacing: '0.48px',
  },
  sectionHeader: {
    fontSize: '22px',
    color: Colors.napaBlue,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.48px'
  },
  sectionContent: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.40px'
  },
  sectionInputHeader: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.36px'
  },
  searchContainer: {
    boxShadow: '0px 0px 4px 0px #00000040',
    padding: '16px'
  }
}

export default styles
