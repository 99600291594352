import { useRef, MutableRefObject, useContext } from 'react';
import { Button, Grid, IconButton, Modal, Typography, Avatar } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { getStringToDate } from '../../../../utilities/helpers/DateTimeFormatters'
import { viewReportStyles } from './viewReportStyles'
import printImage from '../../../../assets/ic-print.png'
import { useReactToPrint } from 'react-to-print';
import language from '../../../../language/language';
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext';
import { apacDateFormat, isApac } from '../../../../utilities/helpers/RegionHelper';

enum ColorType {
    RED = 'Red',
    YELLOW = 'Yellow',
    GREEN = 'Green'
}

const ViewReport = ({
    openViewReport,
    setOpenViewReport,
    report,
    vehicleDetails,
    setReport,
    updateName
}: any
) => {
    const componentRef = useRef() as MutableRefObject<HTMLDivElement>;
    const { currentLanguage } = useContext(LanguageContext)

    const handleOnClose = () => {
        setOpenViewReport(false)
        setReport({})
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const enlargeImage = (zoom: any) => {
        let image = new Image()
        image.src = `data:image/jpeg;base64,${zoom}`
        image.width = 540
        var newTab = window.open("")
        newTab?.document.write(image.outerHTML)
    }

    const renderInspectionHeader = () => {
        return (<>
            <Grid xs={12} sx={viewReportStyles.gridDisplay} marginTop={1}>
                <Typography sx={[viewReportStyles.fontBold, viewReportStyles.titleNormal]}>
                {(language as any)[currentLanguage].ymmcTitle}&nbsp;
                </Typography>
                <Typography sx={viewReportStyles.titleNormal}>
                    {vehicleDetails.year} {vehicleDetails.make} {vehicleDetails.model} {vehicleDetails.color}
                </Typography>
                <Typography sx={[viewReportStyles.fontBold, viewReportStyles.titleNormal]} marginLeft={2}>
                {(language as any)[currentLanguage].licensePlate}&nbsp;
                </Typography>
                <Typography sx={viewReportStyles.titleNormal}>
                    {vehicleDetails.licensePlate}
                </Typography>
                <Typography sx={[viewReportStyles.fontBold, viewReportStyles.titleNormal]} marginLeft={2}>
                {(language as any)[currentLanguage].vinText}&nbsp;
                </Typography>
                <Typography sx={viewReportStyles.titleNormal}>
                    {vehicleDetails.vehicleVin}
                </Typography>
            </Grid>
            <Grid xs={12} sx={viewReportStyles.gridDisplay} marginTop={1}>
                <Typography sx={[viewReportStyles.odometerTitle, viewReportStyles.fontBold]}>
                    {(language as any)[currentLanguage].odometer}:&nbsp;
                </Typography>
                <Typography sx={[viewReportStyles.odometerInput, viewReportStyles.fontNormal]}>
                    {report.odometerReading}
                </Typography>
            </Grid>
            <Grid xs={12} sx={viewReportStyles.gridDisplay} marginTop={1}>
                <Typography sx={[viewReportStyles.fontNormal, viewReportStyles.titleNormal]}>
                {(language as any)[currentLanguage].inspectionCompletedBy} {report.inspectedBy}
                </Typography>
            </Grid>
            <Grid xs={12} sx={viewReportStyles.gridDisplay} marginTop={1}>
                <Typography sx={[viewReportStyles.fontNormal, viewReportStyles.titleNormal]}>
                {(language as any)[currentLanguage].completedOn} {isApac? apacDateFormat(report.inspectionDate) : getStringToDate(report.inspectionDate)}
                </Typography>
            </Grid>
            <Grid xs={12} sx={viewReportStyles.gridDisplay} marginTop={1}>
                <Typography sx={viewReportStyles.inspectionHeader}>
                    Inspection Report
                </Typography>
            </Grid>
        </>)
    }

    const renderEngineLight = (item: any, index: any) => {
        if (index !== 2)
            return null;
        else
            return (
                <Grid xs={6} sx={viewReportStyles.buttonContainer} marginTop={1}>
                    <Typography sx={[viewReportStyles.titleSmall, viewReportStyles.fontNormal]} flex={'auto'}>
                        {(language as any)[currentLanguage].checkEngineLight}
                    </Typography>
                    <Typography sx={[viewReportStyles.titleSmall, viewReportStyles.fontBold]} marginRight={3}>
                        {item.checkEngineLight ? "On" : "Off"}
                    </Typography>
                </Grid>
            )
    }

    const renderInspectionModal = () => {
        return (
            report?.notes?.map((item: any, index: any) => (
                <>
                    <Grid xs={12} sx={viewReportStyles.buttonContainer} marginTop={1} key={`${item.title}${item.id}`}>
                        <Grid xs={6}>
                            <Typography sx={[viewReportStyles.fontBold, viewReportStyles.titleNormal]}>
                                {item.title}
                            </Typography>
                            {item.questions.map((ques: any) => (
                                <Grid xs={12} marginTop={1} key={`${item.title}${ques.id}`}>
                                    <Typography sx={[viewReportStyles.titleSmall, viewReportStyles.fontNormal]}>
                                        {ques.description}
                                    </Typography>
                                    {renderSelectedColor(ques)}
                                </Grid>
                            ))}
                        </Grid>
                        <Grid xs={6}>
                            <Typography sx={[viewReportStyles.fontBold, viewReportStyles.titleNormal]}>
                            {(language as any)[currentLanguage].notes}&nbsp;
                            </Typography>
                            {item.notes && <Grid marginTop={2} marginLeft={1}>
                                <Typography sx={[viewReportStyles.fontBold, viewReportStyles.titleNormal]}>
                                {(language as any)[currentLanguage].comment}
                                </Typography>
                                <Typography sx={[viewReportStyles.titleSmall, viewReportStyles.fontNormal]}>
                                    {item.notes}
                                </Typography>
                            </Grid>}
                            {item.inspectionImages?.length > 0 &&
                                <Typography sx={[viewReportStyles.fontBold, viewReportStyles.titleNormal]} marginLeft={1} marginTop={2}>
                                    Photos
                                </Typography>}
                            <Grid xs={12} container>
                                {item.inspectionImages?.map((image: any, index: any) => {
                                    return (<Grid xs={4} key={index}>
                                        <Button onClick={() => enlargeImage(image)} data-testid='inspection_image'>
                                            <img
                                                src={`data:image/jpeg;base64,${image}`}
                                                alt=''
                                                width={82}
                                                height={84} />
                                        </Button>
                                    </Grid>)
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    {renderEngineLight(item, index)}
                </>
            )
            ))
    }

    const renderSelectedColor = (ques: any) => {
        return (
            <Grid xs={12} sx={viewReportStyles.gridDisplay} container marginTop={1}>
                <Grid xs={4}>
                    <Button
                        disabled
                        sx={ques.color === 'red' ? viewReportStyles.redButtonSelected : viewReportStyles.redButton}
                    >
                        <Typography sx={ques.color === 'red' ? viewReportStyles.boldText : viewReportStyles.redText}>
                            {ColorType.RED}
                        </Typography>
                    </Button>
                </Grid>
                <Grid xs={4}>
                    <Button
                        disabled
                        sx={ques.color === 'yellow' ? viewReportStyles.yellowButtonSelected : viewReportStyles.yellowButton}
                    >
                        <Typography sx={ques.color === 'yellow' ? viewReportStyles.boldText : viewReportStyles.yellowText}>
                            {ColorType.YELLOW}
                        </Typography>
                    </Button>
                </Grid>
                <Grid xs={4}>
                    <Button
                        disabled
                        sx={ques.color === 'green' ? viewReportStyles.greenButtonSelected : viewReportStyles.greenButton}
                    >
                        <Typography sx={ques.color === 'green' ? viewReportStyles.boldText : viewReportStyles.greenText}>
                            {ColorType.GREEN}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'center' }} key={report.index}>
            <Modal
                open={openViewReport}
                onClose={() => handleOnClose()}
                sx={viewReportStyles.viewReportModal}>
                <Grid aria-label="inspection-report-container" ref={componentRef} container sx={viewReportStyles.viewReportModalContainer} key={report.index} className="inspection-report">
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={viewReportStyles.modalTitle}>{updateName || vehicleDetails.vehicleName}</Typography>
                            <Typography sx={viewReportStyles.inspectionHeaderDivider}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1" height="14" viewBox="0 0 1 14" fill="none">
                                    <line x1="0.5" x2="0.5" y2="14" stroke="#E5E7F3" />
                                </svg>
                            </Typography>
                            <Typography aria-label="inspection-report-print-lbl" sx={viewReportStyles.printBtn} onClick={handlePrint}>Print</Typography>
                            <Avatar aria-label="inspection-report-print-icon" sx={viewReportStyles.printIcon} src={printImage} alt={'print'} onClick={handlePrint}></Avatar>
                        </Typography>
                        <IconButton aria-label="inspection-report-close-btn" onClick={() => handleOnClose()} data-testid={'close_icon'}>
                            <ClearIcon fontSize="medium" />
                        </IconButton>
                    </Grid>
                    {renderInspectionHeader()}
                    {renderInspectionModal()}
                    <Grid xs={12} sx={viewReportStyles.viewReportModal}>
                        <Button
                            onClick={() => handleOnClose()}
                            data-testid={'close_button'}>
                            <Typography sx={viewReportStyles.closeButton}>
                                {(language as any)[currentLanguage].close}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    )
}

export default ViewReport