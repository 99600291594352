import { useQuery } from 'react-query'
import { FeatureConfigurationKeys } from '../keys'
import { FeatureConfigurationService } from '../../utilities/services/FeatureConfigurationService'
import { useContext } from 'react'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

export const useFeatureConfiguration = () => {
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  return useQuery([FeatureConfigurationKeys.GetFeatureConfiguration], (context) => FeatureConfigurationService.getFeatureConfiguration(), {
    onError: (_err) => {
      addSnack({
        severity: 'error',
        message: (language as any)[currentLanguage].errorFeatureConfiguration,
        duration: 3000
      })
    },
    retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
  })
}
