import { createContext, ReactElement} from 'react'
import { featureConfiguration } from '../types/FeatureConfigurationTypes'
import { useFeatureConfiguration } from '../../queries/FeatureConfiguration/useFeatureConfiguration'

export const BusinessUnitConfigContext = createContext<BusinessUnitConfigContextType>({
    featureConfigurations: [],
})

type BusinessUnitConfigContextType = {
    featureConfigurations: Array<featureConfiguration>;
}

export const BusinessUnitConfigProvider = ({children}: any): ReactElement => {
 
    const { data: featureConfigurations } = useFeatureConfiguration()
      
    return (
      <BusinessUnitConfigContext.Provider
        value={{
            featureConfigurations
        }
        }>
        {children}
      </BusinessUnitConfigContext.Provider>
    );
  };
