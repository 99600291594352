import { useContext } from 'react'
import { AuthContext as ApacAuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import NavBarMenu from './NavBarMenu'
import { Button, CircularProgress } from '@mui/material'
import { StoreProvider } from '../../utilities/contexts/StoreContext'
import NavigationBar from '../NavigationBar/NavigationBar'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import TermsAndConditions from '../../screens/TermsAndConditions/TermsAndConditions'
import { BusinessUnitConfigProvider } from '../../utilities/contexts/businessUnitConfigContext'

const ApacNavigator = () => {
  const { token, loginInProgress, logIn } = useContext<IAuthContext>(ApacAuthContext)
  const { termsAccepted, isInitializingTerms, isInitializingCurrentUser, currentUser } = useContext(AuthContext)

  if (loginInProgress || isInitializingTerms || isInitializingCurrentUser) {
    return (
      <div className="Loading-container">
        <CircularProgress />
      </div>
    )
  } else if (token && currentUser) {
    if (termsAccepted) {
      return (
        <StoreProvider>
          <BusinessUnitConfigProvider>
            <NavigationBar />
            <NavBarMenu />
          </BusinessUnitConfigProvider>
        </StoreProvider>
      )
    } else {
      return <TermsAndConditions />
    }
  } else {
    return (
      <h4>
        Please <Button onClick={() => logIn()}>Sign In</Button>
      </h4>
    )
  }
}
export default ApacNavigator
