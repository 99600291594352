import { Dialog, IconButton, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'

type CustomStopProps = {
    openCustomStop: boolean
    setOpenCustomStop: (value: boolean) => void
}
const AddCustomStop = ({
    openCustomStop,
    setOpenCustomStop
}: CustomStopProps) => {
    
    const handleClose = () => {
        setOpenCustomStop(false)
    }

    return (
        <Dialog open={openCustomStop} fullWidth={true} onClose={handleClose}>
            <Typography >
                Add Custom Stop
            </Typography>
            <IconButton onClick={handleClose}>
                <Clear fontSize='medium' />
            </IconButton>
        </Dialog>
    )
}

export default AddCustomStop
