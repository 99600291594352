import mapboxgl from "mapbox-gl";
import { createRoot } from "react-dom/client";
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import { ReactComponent as DropPinIcon } from '../../assets/dropPin.svg';

type CreateMarkerElemType = {
  className?: String,
  backgroundColor?: String,
  opacity?: String
}

// create invoice marker elements
export const createMarkerElement = (options: CreateMarkerElemType) => {
  const { className = '', backgroundColor = '', opacity = '1' }: any = options;
  const el = document.createElement('div');
  el.className = `${className}`;
  el.style.backgroundColor = backgroundColor;
  el.style.opacity = opacity;
  return el;
};

export const addStoreMarker = (fontSize: string): mapboxgl.Marker => {
    const storeMarkerElement = document.createElement('div');
    const root = createRoot(storeMarkerElement);
    root.render(
      <StoreMallDirectoryOutlinedIcon
        color="primary"
        style={{
          backgroundSize: 'cover',
          borderRadius: '50%',
          cursor: 'pointer',
          filter: 'brightness(0.25)',
          fontSize: fontSize
        }}
      />
    );
    return new mapboxgl.Marker(storeMarkerElement);
  };

  export const addressMarker = (fontSize: string): mapboxgl.Marker => {
    const addressMarkerElement = document.createElement('div');
    const root = createRoot(addressMarkerElement);
    root.render(
      <PlaceIcon
        color="primary"
        style={{
          backgroundSize: 'cover',
          borderRadius: '50%',
          cursor: 'pointer',
          filter: 'brightness(0.25)',
          fontSize: fontSize
        }}
      />
    );
    return new mapboxgl.Marker(addressMarkerElement);
  };

  export const dropPinMarker = (): mapboxgl.Marker => {
    const dropPinMarker = document.createElement('div');
    const root = createRoot(dropPinMarker);
    root.render(
      <DropPinIcon/>
    );
    return new mapboxgl.Marker(dropPinMarker);
  };