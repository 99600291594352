import { Box, Button, Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { customerDetailsStyles } from './CustomerDetailsStyles'
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg'
import { ReactComponent as StarIcon } from '../../../assets/star.svg'
import { useContext, useState } from "react";
import { CustomerDetails, CustomerService, CustomerAddressPayload, formatARNumber, formatPhoneNumber, validateLatitude, validateLongitude, AddressInfo, CustomerAddressResponse, DeliveryGeoCodes } from "../../../utilities/services/CustomerService/CustomerService";
import CustomerAddressView from "./CustomerAddress/CustomerAddressView";
import CustomerAddress from "./CustomerAddress/CustomerAddress";
import DropPinMap from "../CustomerDetailsList/DropPinMap";
import { SnackbarContext } from "../../../utilities/contexts/SnackbarContext";
import { LanguageContext } from "../../../utilities/contexts/LanguageContext";
import language from "../../../language/language";
import { AuthContext } from '../../../utilities/contexts/AuthContext';

interface InputProps {
    customerDetails: CustomerDetails | null | undefined,
    onBackPageClick: () => void
    onCustomerUpdate: (updatedAddress: CustomerAddressResponse) => void
}
const centerLngLat:[number, number] = [-98.5795, 39.8283]
const CustomerDetailsView = ({ customerDetails, onBackPageClick, onCustomerUpdate }: InputProps) => {
    const { currentLanguage } = useContext(LanguageContext)
    const { currentUser } = useContext(AuthContext)
    const [serviceLevelEdit, setServiceLevelEdit] = useState<boolean>(false);
    const [addressEdit, setAddressEdit] = useState<number>(-1);
    const [lnglat, setLngLat] = useState<[number, number] | null>(centerLngLat);
    const { addSnack } = useContext(SnackbarContext)
    const serviceLevels = [
        { displayName: "Platinum", fill: '#F4F4F4' },
        { displayName: "Gold", fill: '#FFE395' },
        { displayName: "Silver", fill: '#ADADAD' },
        { displayName: "Bronze", fill: '#E49600' }
    ]
    const userIsOwnerOrManager =  (currentUser?.userType === 'district_manager' || currentUser?.userType === 'owner')

    const updateCustomerAddress = async (customerAddress: CustomerAddressPayload) => {
        try {
            let updateAddress;
            if (!customerAddress.addressId) {
                updateAddress = await CustomerService.saveCustomer(customerAddress)
            } else {
                updateAddress = await CustomerService.updateCustomer(customerAddress)
            }
            addSnack({
                severity: 'success',
                message: 'success',
                action: null,
                duration: 3000
            })
            setAddressEdit(-1)
            onCustomerUpdate(updateAddress)
        } catch (e: any) {
            addSnack({
                severity: 'error',
                message: 'error',
                action: null,
                duration: 3000
            })
        }
    }

    const serviceLevelMenuItems = serviceLevels.map((serviceLevel) => {
        return <MenuItem value={serviceLevel.displayName}>
            <Box sx={customerDetailsStyles.serviceLevel}>
                <StarIcon style={{ fill: serviceLevel.fill }} />
                <Typography>{serviceLevel.displayName}</Typography>
            </Box>
        </MenuItem>
    })

    return <Grid container direction="column" padding={2} gap={2}>
        <Grid item display="flex">
            <IconButton sx={customerDetailsStyles.typoSmall} onClick={onBackPageClick}>
                <ArrowBack style={{ paddingRight: "8px" }} /> <div>
                    {(language as any)[currentLanguage].backToResults}
                </div>
            </IconButton>
        </Grid>
        <Grid>
            <Typography align="left" sx={customerDetailsStyles.typoHeader3}>{customerDetails?.customerInfo?.name}</Typography>
        </Grid>
        <Grid>
            <Typography align="left" sx={customerDetailsStyles.typoHeader5}>
                {(language as any)[currentLanguage].accountDetails}    
            </Typography>
        </Grid>
        <Grid container spacing={2} xs={12} sx={customerDetailsStyles.accountDetailsLayout}>
            <Grid item xs={2} paddingBottom='16px'>
                <Typography sx={customerDetailsStyles.paragraphBold2}>
                    {(language as any)[currentLanguage].arNumber}
                </Typography>
                <Typography sx={customerDetailsStyles.paragraph2}>{formatARNumber(customerDetails?.customerInfo?.accountInfo?.[0]?.accountReceivableNumber?.[0] || '')}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={customerDetailsStyles.paragraphBold2}>
                    {(language as any)[currentLanguage].phoneNumber}
                </Typography>
                <Typography sx={customerDetailsStyles.paragraph2}>{formatPhoneNumber(customerDetails?.customerInfo?.contactInfo?.[0].phoneNumber?.[0] || '')}</Typography>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: '200px' }}>
                <Typography sx={{ ...customerDetailsStyles.paragraphBold2, height: '26px' }}>
                    {(language as any)[currentLanguage].serviceLevel}
                    {!serviceLevelEdit && <Button variant="text" disabled={!userIsOwnerOrManager}
                        sx={customerDetailsStyles.paragraph2Link}
                        onClick={() => {
                            setServiceLevelEdit(true)
                        }}
                    >
                        {(language as any)[currentLanguage].edit}
                    </Button>}
                </Typography>
                {!serviceLevelEdit && <Box sx={customerDetailsStyles.serviceLevel}>
                    <StarIcon></StarIcon>
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        Gold</Typography>
                </Box>}
                {!!serviceLevelEdit &&
                    <Box sx={customerDetailsStyles.serviceLevel}>
                        <Select>
                            {serviceLevelMenuItems}
                        </Select>
                        <Button variant="text"
                            sx={customerDetailsStyles.paragraph2Link}
                            onClick={() => {
                                setServiceLevelEdit(false)
                            }}
                        >
                            {(language as any)[currentLanguage].save}
                        </Button>
                    </Box>
                }
            </Grid>
            <Grid item xs={6}>

            </Grid>
        </Grid>
        <Grid>
            <Typography align="left" sx={customerDetailsStyles.typoHeader5}>
                {(language as any)[currentLanguage].deliveryInformation}
            </Typography>
        </Grid>
        {customerDetails?.addressInfo?.map((addressInfo, addressIndex) => {
            const deliveryInfo = addressInfo?.deliveryGeocodes?.[0];
            return <Grid container item xs={12} display="flex" flexDirection="row" padding={2} columnSpacing={"40px"}>
                <Grid item xs={6}>
                    {addressEdit !== addressIndex &&
                        <CustomerAddressView addressInfo={addressInfo} onEdit={()=>{
                            setAddressEdit(addressIndex)
                        }}
                        customerInfo={customerDetails?.customerInfo}
                        index={addressIndex}
                        ></CustomerAddressView>
                    }
                    {addressEdit === addressIndex &&
                        <CustomerAddress customerInfo={customerDetails?.customerInfo} addressInfo={addressInfo} onSave={updateCustomerAddress}
                            onCancel={()=>{
                                customerDetails.addressInfo = customerDetails?.addressInfo.filter(info=>{
                                    return !!info.addressId;
                                })
                                setAddressEdit(-1)
                            }} lngLat={lnglat}
                            setLngLat={setLngLat}
                            index={addressIndex}></CustomerAddress>
                    }
                </Grid>
                <Grid item xs={6} height={"255px"}>
                    <DropPinMap lngLat={addressEdit === addressIndex ? lnglat : null} setLngLat={setLngLat} someAddress={
                        {
                            latitude: validateLatitude(+deliveryInfo?.latitude || centerLngLat[1]), 
                            longitude: validateLongitude(+deliveryInfo?.longitude || centerLngLat[0])
                        }
                    } isDraggable={addressEdit === addressIndex}/>
                </Grid>
            </Grid>
        })}
        <Box display="flex" alignItems="start">
            <Button sx={customerDetailsStyles.paragraph2Link}
                onClick={()=>{
                    const newAddress = {
                        deliveryGeocodes: [{} as DeliveryGeoCodes]
                    } as AddressInfo
                    //set isDefault to false for new address drop location
                    customerDetails?.addressInfo.push({...newAddress,
                        deliveryGeocodes: [{
                            ...newAddress.deliveryGeocodes[0],
                            isDefault: 'N'
                        }]
                    })
                    setAddressEdit((customerDetails?.addressInfo || []).length-1)
                }}>+ {(language as any)[currentLanguage].additionalLocation}</Button>
        </Box>
    </Grid>
}

export default CustomerDetailsView;