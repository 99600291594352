import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import InvoiceList from './components/InvoiceList/InvoiceList'
import { routeBuilderStyles } from './routeBuilderStyles'
import { RouteList } from './components/RouteList/RouteList'
import Colors from '../../assets/Colors'
import { DeliveryRouteService } from '../../utilities/services/DeliveryRouteService'
import { DeliveryRoute, Invoice, InvoicesResponse } from '../../utilities/types/DeliveryRouteTypes'
import { useInvoicesByStore } from '../../queries/Invoices/useInvoicesByStore'
import EditAddInvoices from './components/EditAddInvoices/EditAddInvoices'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import ManualAddInvoice from './components/ManualAddInvoice/ManualAddInvoice'
import { useDeliveryRoutesByStore } from '../../queries/DeliveryRoutes/useDeliveryRoutesByStore'
import EditRoute from './components/EditRoute/EditRoute'
import CompletedRoute from './components/CompletedRoute/CompletedRoute'
import CancelRoute from './components/CancelRoute/CancelRoute'
import InvoicesMapView from './components/InvoicesMapView/InvoicesMapView'
import RoutessMapView from './components/RoutesMapView/RoutesMapView'
import { RouteBuilderService } from '../../utilities/services/RouteBuilderService'
import { useCache } from '../../utilities/hooks/apiCache'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import InvoiceDetail from './components/InvoiceDetail/InvoiceDetail'
import InvoiceAction from './components/InvoiceAction/InvoiceAction'
import { makeEndDate, makeStartDate } from './components/DateFilter/Dates'
import EmptyBuildRoute from './components/EmptyBuildRoute/EmptyBuildRoute'
import { useFlags } from 'launchdarkly-react-client-sdk'
import AddCustomStop from './components/AddCustomStop/AddCustomStop'

export const RouteBuilderContext = createContext<RouteBuilderContextType>({
  setSelectedInvoices: () => { },
  selectedInvoices: [],
  displayInvoices: [],
  setDisplayInvoices: () => { },
  filteredInvoices: [],
  setFilteredInvoices: () => { },
  setFilteredRoutes: () => { },
  filteredRoutes: [],
  startDate: null,
  endDate: null
})

type RouteBuilderContextType = {
  selectedInvoices: Array<any>
  setSelectedInvoices: Dispatch<SetStateAction<Array<any>>>
  displayInvoices: Array<any>
  setDisplayInvoices: Dispatch<SetStateAction<Array<any>>>
  filteredInvoices: Array<any>
  setFilteredInvoices: Dispatch<SetStateAction<Array<any>>>
  filteredRoutes: Array<any>
  setFilteredRoutes: Dispatch<SetStateAction<Array<any>>>
  startDate: number | null
  endDate: number | null
}

export type RouteBuilderTab = 'unassignedInvoices' | 'holdInvoices' | 'routes' | 'autoDispatchedRoutes'

enum ActionType {
  remove = 'remove',
  markAsDelivered = 'markAsDelivered'
}

const RouteBuilder = () => {
  const { getCache, setCache } = useCache()
  const { addSnack } = useContext(SnackbarContext)
  const { currentStore, autoDispatchEnabled } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const [selectedInvoices, setSelectedInvoices] = useState<Array<string>>([])
  const [displayInvoices, setDisplayInvoices] = useState<Array<string>>([])
  const [filteredInvoices, setFilteredInvoices] = useState<Array<string>>([])
  const [filteredRoutes, setFilteredRoutes] = useState<Array<string>>([])
  const [openEditRoute, setOpenEditRoute] = useState<boolean>(false)
  const [openCompletedRoute, setOpenCompletedRoute] = useState<boolean>()
  const [manualAddInvoiceOpen, setManualAddInvoiceOpen] = useState<boolean>(false)
  const [openEditAddInvoices, setOpenAddInvoices] = useState<boolean>(false)
  const [routesToggle, setRoutesToggle] = useState<RouteBuilderTab>('unassignedInvoices')

  const [selectedDriverId, setSelectedDriverId] = useState<string>('None')
  const [createRouteLabel, setCreateRouteLabel] = useState<string>(
    (language as any)[currentLanguage].buildRouteText
  )

  const [completedRouteData, setCompletedRouteData] = useState<any>(null)
  // This state holds the edited route data(removes, adds any invoice)
  const [editedRouteData, setEditedRouteData] = useState<DeliveryRoute | null>(null)
  const [editRemoveInvoices, setEditRemoveInvoices] = useState<Array<Invoice>>([])
  // This state holds the actual route data
  const [route, setRoute] = useState<DeliveryRoute | null>(null)
  const [editAddInvoices, setEditAddInvoices] = useState<Array<string>>([])
  const [editAddInvoiceData, setEditAddInvoiceData] = useState<Array<any>>([])

  const lastThreeDays = new Date(new Date().getTime() - 72 * 60 * 60 * 1000).getTime()
  //The below state is used to store the date range selections
  const [startDateFilter, setStartDateFilter] = useState<number>(makeStartDate(lastThreeDays))
  const [endDateFilter, setEndDateFilter] = useState<number>(makeEndDate(new Date().getTime()))

  /**The below state is used to store the date range when the user does the search,
      we need to maintain two different date range states as we are adding this start and end dates to useQuery dependency array .**/
  const [startDate, setStartDate] = useState<number>(makeStartDate(lastThreeDays))
  const [endDate, setEndDate] = useState<number>(makeEndDate(new Date().getTime()))
  const [filteredRoutesList, setFilteredRoutesList] = useState<DeliveryRoute[] | undefined>()
  const [openCancelRoute, setOpenCancelRoute] = useState<boolean>(false)
  const [showMap, setShowMap] = useState(false)
  const [openEditInvoice, setOpenEditInvoice] = useState<boolean>(false)
  const [invoiceDetails, setInvoiceDetails] = useState<InvoicesResponse | null>(null)
  const [openInvoiceActions, setOpenInvoiceActions] = useState<boolean>(false)
  const [holdInvoicesList, setHoldInvoicesList] = useState<InvoicesResponse[]>([])
  const [unassignednvoicesList, setUnassignednvoicesList] = useState<InvoicesResponse[]>([])
  const [invoiceActionType, setInvoiceActionType] = useState<ActionType>(ActionType.markAsDelivered)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [invoiceAction, setInvoiceAction] = useState<InvoicesResponse | null>(null)
  const [openEmptyModal, setOpenEmptyModal] = useState<boolean>(false)
  const [openCustomStop, setOpenCustomStop] = useState<boolean>(false)

  const { isCustomStopEnabled } = useFlags()
  const isInvoiceTab = () => (routesToggle === 'unassignedInvoices' || routesToggle === 'holdInvoices')

  const {
    data: invoices,
    refetch: refetchInvoices,
    isFetching: isFetchingInvoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices
  } = useInvoicesByStore(startDate, endDate, isInvoiceTab())


  const isAutoDispatch = (route: DeliveryRoute) => {
    return route.stops?.filter(stop => stop.invoices?.filter(invoice => invoice.autoDispatch).length > 0).length > 0
  }

  const isRoutesTab =(route: DeliveryRoute) => {
    return route.stops?.filter(stop => stop.invoices?.filter(invoice => invoice.autoDispatch).length === 0).length > 0
  }

  const {
    data: routes,
    refetch: refetchRoutes,
    isLoading: isLoadingRoutes,
    isFetching: isFetchingRoutes
  } = useDeliveryRoutesByStore(startDate, endDate, routesToggle === 'routes' || routesToggle === 'autoDispatchedRoutes')

  useEffect(() => {
    const _filteredRoutes = routesToggle === 'routes' ? routes : routes?.filter(route => isAutoDispatch(route))
    setFilteredRoutesList(_filteredRoutes)
  }, [routes, routesToggle])


  useEffect(() => {
    if (invoices) {
      setHoldInvoicesList(invoices?.filter(list => list.nssaInvoiceStatus === "ON_HOLD"))
      setUnassignednvoicesList(invoices?.filter(list => list.nssaInvoiceStatus !== "ON_HOLD"))
    }
    else {
      setHoldInvoicesList([])
      setUnassignednvoicesList([])
    }
  }, [invoices])

  // Get yesterday's date and today's date
  const getStartdateAndEnddatesForRoute = () => {
    const yesterday = new Date(endDate - 24 * 60 * 60 * 1000);
    
    // Set time to min of yesterday.
    yesterday.setHours(0, 0, 0, 0);

    return {
      routeStartDate: yesterday.getTime(),
      routeEndDate: endDate
    };
  };

  const { routeStartDate, routeEndDate } = getStartdateAndEnddatesForRoute();
  const { data: deliveryRoutesByStore} = useDeliveryRoutesByStore(routeStartDate, routeEndDate, true);

  const getAutoDispatchedRoutesCount = () :number=>{
    return ['unassignedInvoices','holdInvoices'].includes(routesToggle) ? deliveryRoutesByStore?.filter(route=>isAutoDispatch(route)).length || 0 : (routes?.filter(route=>isAutoDispatch(route)).length || 0)
  }

  const getRoutesCount = () : number =>{
    return ['unassignedInvoices','holdInvoices'].includes(routesToggle) ? deliveryRoutesByStore?.filter(route=>isRoutesTab(route)).length || 0 : (routes?.filter(route=>isRoutesTab(route)).length || 0)    
  }

  // Adding an useEffect to render spinner.
  useEffect(() => {
    if (isFetchingRoutes || isFetchingInvoices) {
      setShowSpinner(true)
    } else {
      // Using timeout to set showSpinner false delayed by 750ms.
      setTimeout(() => {
        setShowSpinner(false)
      }, 750)
    }
  }, [isFetchingRoutes, isFetchingInvoices])

  const clearEditedRouteData = () => {
    setEditAddInvoices([])
    setEditAddInvoiceData([])
    setEditRemoveInvoices([])
    setEditedRouteData(null)
    setRoute(null)
  }

  const clearSelectedInvoiceData = () => {
    setSelectedInvoices([])
    setSelectedDriverId('None')
    setInvoiceAction(null)
  }

  const resetDefaultDateRangeforInvoices = () => {
    const currentDate = new Date()
    const startDateTimestamp = new Date(currentDate.getTime() - 72 * 60 * 60 * 1000)
    startDateTimestamp.setHours(0, 0, 0, 0)
    setStartAndEndDates(startDateTimestamp.getTime(), makeEndDate(new Date().getTime()))
  }

  const handleUpdateRoute = async () => {
    let updateRequest = {
      storeNumber: currentStore,
      invoices: selectedInvoices,
      deliveryDriver: selectedDriverId === 'None' ? null : selectedDriverId,
      routeStatus: selectedDriverId === 'None' ? 'created' : 'pending'
    }
    try {
      // TODO: don't want to use the whole update route... maybe use the add driver endpoint/ add invoices /etc so that the state is kept up to date in backend and frontend
      const response: any = await DeliveryRouteService.createRoute(updateRequest)
      addSnack({
        severity: 'success',
        message: `Route #${response.data.routeId}${(language as any)[currentLanguage].routeCreated
          }`,
        action: null,
        duration: 3000
      })
      clearSelectedInvoiceData()
      setOpenEditRoute(false)
      setCreateRouteLabel((language as any)[currentLanguage].buildRouteText)
      setSelectedDriverId('None')
    } catch (e: any) {
      addSnack({
        severity: 'error',
        message: e.message.contains('Invoice status is')
          ? language[currentLanguage].errorRouteCreationInvoiceOnHold
          : language[currentLanguage].errorRouteCreation,
        duration: 3000,
        action: { prompt: 'REFRESH', callback: () => window.location.reload() }
      })
    } finally {
      refetchInvoices()
    }
  }

  const handleCreateRouteClick = async () => {
    if(selectedInvoices.length !== 0){
      let createRouteRequest = {
        routeStatus: 'created',
        invoices: selectedInvoices,
        storeNumber: currentStore,
        deliveryDriver: null
      }

      try {
        const response: any = await DeliveryRouteService.createRoute(createRouteRequest)
        setRoute(response.data)
        setEditedRouteData(response.data)
        setOpenEditRoute(true)
        setSelectedDriverId('None')
      } catch (e: any) {
        const message = e.message.includes('Invoice status')
          ? language[currentLanguage].errorRouteCreationInvoiceOnHold
          : language[currentLanguage].errorRouteCreation

        console.log(e.message)
        console.log(message)

        addSnack({
          severity: 'error',
          message: message,
          duration: 3000,
          action: { prompt: 'REFRESH', callback: () => window.location.reload() }
        })
      } finally {
        refetchInvoices()
      }
    }
    else
      setOpenEmptyModal(true)
  }

  //This triggers the API calls
  const setStartAndEndDates = (startDate: any, endDate: any) => {
    setStartDateFilter(startDate)
    setEndDateFilter(endDate)
    startDate && setStartDate(startDate)
    endDate && setEndDate(endDate)
  }

  const showMapEvent = () => {
    if (!showMap) {
    }
    setShowMap(!showMap)
  }

  const setDefaultDateRangeForRoutes = () => {
    const currentDate = new Date()
    const startDateTimestamp = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)
    startDateTimestamp.setHours(0, 0, 0, 0)
    setStartAndEndDates(startDateTimestamp.getTime(), makeEndDate(currentDate.getTime()))
  }

  const getMapRouteCoordinates = async (coordinates: string): Promise<any> => {
    const routeCache = getCache(coordinates)
    if (routeCache) return routeCache // Return cached data
    try {
      const data = await RouteBuilderService.getMapRouteCoordinates({ coordinates })
      if (data && data.code !== 'Ok') {
        throw new Error(data.message)
      }
      const trips = data.routes[0]
      const route = trips.geometry.coordinates
      const routeCoordinates = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: route
        },
        distanceInMiles: Math.round(trips.distance * 0.000621371),
        durationInMins: Math.round(trips.duration / 60)
      }
      setCache(coordinates, routeCoordinates) // Store the API response in the cache
      return routeCoordinates
    } catch (e: any) {
      console.error(e)
      if (e?.message.includes('No')) {
        throw new Error(e.message)
      } else {
        throw new Error(e.response.data.message)
      }
    }
  }

  return (
    <RouteBuilderContext.Provider
      value={{
        selectedInvoices,
        setSelectedInvoices,
        displayInvoices,
        setDisplayInvoices,
        filteredInvoices,
        setFilteredInvoices,
        filteredRoutes,
        setFilteredRoutes,
        startDate,
        endDate
      }}>
      {ManualAddInvoice({
        manualAddInvoiceOpen: manualAddInvoiceOpen,
        setManualAddInvoiceOpen: setManualAddInvoiceOpen
      })}
      <EditRoute
        setEditAddInvoiceData={setEditAddInvoiceData}
        setRoute={setRoute}
        route={route}
        setEditedRouteData={setEditedRouteData}
        editAddInvoiceData={editAddInvoiceData}
        setOpenAddInvoices={setOpenAddInvoices}
        editRemoveInvoices={editRemoveInvoices}
        setEditRemoveInvoices={setEditRemoveInvoices}
        selectedDriverId={selectedDriverId}
        setSelectedDriverId={setSelectedDriverId}
        editAddInvoices={editAddInvoices}
        routeDetails={editedRouteData}
        clearEditedRouteData={clearEditedRouteData}
        openEditRoute={openEditRoute}
        setOpenEditRoute={setOpenEditRoute}
        refetchRoutes={refetchRoutes}
        isFetchingRoutes={isFetchingRoutes}
        setOpenCancelRoute={setOpenCancelRoute}
        getMapRouteCoordinates={getMapRouteCoordinates}
        setOpenCustomStop={setOpenCustomStop}
      />
      <EmptyBuildRoute
        openEmptyModal={openEmptyModal}
        setOpenEmptyModal={setOpenEmptyModal}
        selectedDriverId={selectedDriverId}
        setSelectedDriverId={setSelectedDriverId}
        setOpenAddInvoices={setOpenAddInvoices}
        setOpenCustomStop={setOpenCustomStop}
      />
      <AddCustomStop
        openCustomStop={openCustomStop}
        setOpenCustomStop={setOpenCustomStop}
      />
      <InvoiceDetail
        setInvoiceDetails={setInvoiceDetails}
        setOpenEditInvoice={setOpenEditInvoice}
        invoiceDetails={invoiceDetails}
        openEditInvoice={openEditInvoice}
        refetchInvoices={refetchInvoices}
        setOpenInvoiceActions={setOpenInvoiceActions}
        setInvoiceActionType={setInvoiceActionType}
      />
      <CompletedRoute
        completedRouteData={completedRouteData}
        openCompletedRoute={openCompletedRoute}
        setOpenCompletedRoute={setOpenCompletedRoute}
      />
      <EditAddInvoices
        openEditAddInvoices={openEditAddInvoices}
        setOpenAddInvoices={setOpenAddInvoices}
        setEditAddInvoices={setEditAddInvoices}
        setEditAddInvoiceData={setEditAddInvoiceData}
        editAddInvoices={editAddInvoices}
      />
      <CancelRoute
        openCancelRoute={openCancelRoute}
        clearEditedRouteData={clearEditedRouteData}
        setOpenCancelRoute={setOpenCancelRoute}
        setOpenEditRoute={setOpenEditRoute}
        routeId={editedRouteData?.routeId}
        externalDeliveryId={editedRouteData?.externalDelivery?.externalDeliveryId}
      />
      <InvoiceAction
        openInvoiceActions={openInvoiceActions}
        setOpenInvoiceActions={setOpenInvoiceActions}
        setOpenEditInvoice={setOpenEditInvoice}
        invoiceNumber={invoiceDetails?.invoiceNumber ?? invoiceAction?.invoiceNumber}
        invoiceDateTime={invoiceDetails?.invoiceDateTime ?? invoiceAction?.invoiceDateTime}
        refetchInvoices={refetchInvoices}
        actionType={invoiceActionType}
      />
      {showMap && isInvoiceTab() && (
        <Grid className="testGrid" item xs={12} sx={routeBuilderStyles.gridMapItem}>
          <InvoicesMapView currentTab={routesToggle} />
        </Grid>
      )}
      {showMap && (routesToggle === 'routes' || routesToggle === 'autoDispatchedRoutes') && (
        <Grid className="testGrid" item xs={12} sx={routeBuilderStyles.gridMapItem}>
          <RoutessMapView showAutoDispatch={routesToggle === 'autoDispatchedRoutes'} />
        </Grid>
      )}

      <Grid container spacing={2} style={routeBuilderStyles.gridContainer}>
        <Grid item xs={12} sx={routeBuilderStyles.gridItem}>
          <Grid item xs={8} sx={routeBuilderStyles.gridRouteBuilder}>
            <Typography data-testid={'route_builder'} sx={routeBuilderStyles.routeBuilderTitle}>
              {(language as any)[currentLanguage].routeBuilder}
              {
                <span onClick={showMapEvent} style={routeBuilderStyles.showMap}>
                  {showMap
                    ? (language as any)[currentLanguage].hideMap
                    : (language as any)[currentLanguage].showMap}
                </span>
              }
              {
                showSpinner && (
                  <CircularProgress data-testid={'spinner'} size={14} style={{ marginLeft: 10 }} />
                )
              }
            </Typography>
          </Grid>
          <Grid item xs={4} sx={routeBuilderStyles.addInvoiceGrid}>
            <Button
              variant="secondary"
              onClick={() => setManualAddInvoiceOpen(true)}
              sx={routeBuilderStyles.addInvoiceButton}>
              {(language as any)[currentLanguage].addInvoice}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (createRouteLabel === 'Update Route') {
                  handleUpdateRoute()
                } else {
                  handleCreateRouteClick()
                }
              }}
              disabled={!(isCustomStopEnabled || (selectedInvoices.length > 0))}
              sx={routeBuilderStyles.createRouteButton}>
              {(language as any)[currentLanguage].buildRouteText}
            </Button>
          </Grid>
        </Grid>

        <Grid
          style={{
            transition: 'margin-top 0.3s'
          }}>
          <Button
            onClick={() => {
              setRoutesToggle('unassignedInvoices')
              clearSelectedInvoiceData()
              clearEditedRouteData()
              resetDefaultDateRangeforInvoices()
            }}
            sx={{
              ...routeBuilderStyles.invoicesTabFont,
              color: routesToggle === 'unassignedInvoices' ? Colors.napaBlue : 'black',
              fontWeight: routesToggle === 'unassignedInvoices' ? 700 : 400,
              borderBottom: routesToggle === 'unassignedInvoices' ? 'solid' : 'none'
            }}>
            {`${(language as any)[currentLanguage].unassignedInvoice} (${unassignednvoicesList?.length})`}
          </Button>
          <Button
            onClick={() => {
              setRoutesToggle('holdInvoices')
              clearSelectedInvoiceData()
              clearEditedRouteData()
              resetDefaultDateRangeforInvoices()
            }}
            sx={{
              ...routeBuilderStyles.invoicesTabFont,
              color: routesToggle === 'holdInvoices' ? Colors.napaBlue : 'black',
              fontWeight: routesToggle === 'holdInvoices' ? 700 : 400,
              borderBottom: routesToggle === 'holdInvoices' ? 'solid' : 'none'
            }}>
            {`${(language as any)[currentLanguage].holdInvoice} (${holdInvoicesList?.length})`}
          </Button>
          {autoDispatchEnabled && <Button
            onClick={() => {
              setRoutesToggle('autoDispatchedRoutes')
              clearSelectedInvoiceData()
              setDefaultDateRangeForRoutes()
            }}
            sx={{
              ...routeBuilderStyles.invoicesTabFont,
              color: routesToggle === 'autoDispatchedRoutes' ? Colors.napaBlue : 'black',
              fontWeight: routesToggle === 'autoDispatchedRoutes' ? 700 : 400,
              borderBottom: routesToggle === 'autoDispatchedRoutes' ? 'solid' : 'none'
            }}>
            {`${(language as any)[currentLanguage].autoDispatchedRoutes} (${getAutoDispatchedRoutesCount()})`}
          </Button>}
          <Button
            onClick={() => {
              setRoutesToggle('routes')
              clearSelectedInvoiceData()
              setDefaultDateRangeForRoutes()
            }}
            sx={{
              ...routeBuilderStyles.routeTabFont,
              color: routesToggle === 'routes' ? Colors.napaBlue : 'black',
              fontWeight: routesToggle === 'routes' ? 700 : 400,
              borderBottom: routesToggle === 'routes' ? 'solid' : 'none'
            }}>
            {`${(language as any)[currentLanguage].routesTitle} (${getRoutesCount()})`}
          </Button>
        </Grid>

        {routesToggle !== 'routes' && routesToggle !== 'autoDispatchedRoutes' ? (
          <InvoiceList
            invoices={routesToggle === 'unassignedInvoices' ? unassignednvoicesList : holdInvoicesList}
            setInvoiceDetails={setInvoiceDetails}
            setOpenEditInvoice={setOpenEditInvoice}
            setStartAndEndDates={setStartAndEndDates}
            startDate={startDateFilter}
            endDate={endDateFilter}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            isErrorInvoices={isErrorInvoices}
            isLoadingInvoices={isLoadingInvoices}
            fetchInvoices={() => {
              refetchInvoices()
            }}
            currentTab={routesToggle}
            setOpenInvoiceActions={setOpenInvoiceActions}
            setInvoiceActionType={setInvoiceActionType}
            setInvoiceAction={setInvoiceAction}
          />
        ) : (
          <RouteList
            routes={filteredRoutesList}
            isLoadingRoutes={isLoadingRoutes}
            setEditedRouteData={setEditedRouteData}
            setRoute={setRoute}
            setSelectedDriverId={setSelectedDriverId}
            setCompletedRouteData={setCompletedRouteData}
            setOpenEditRoute={setOpenEditRoute}
            setOpenCompletedRoute={setOpenCompletedRoute}
            startDate={startDateFilter}
            endDate={endDateFilter}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            setStartAndEndDates={setStartAndEndDates}
            routesToggle={routesToggle === 'routes'}
            currentTab={routesToggle}
          />
        )}
      </Grid>
    </RouteBuilderContext.Provider>
  )
}

export default RouteBuilder
