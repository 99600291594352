import { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { ArrowBackOutlined, CheckCircleOutline, EditOutlined, WarningAmberOutlined } from '@mui/icons-material'
import { MyFleetService } from '../../../../utilities/services/MyFleetService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { useInspectionByVehicle } from '../../../../queries/MyFleets/useInspectionByVehicle'
import { getStringToDate } from '../../../../utilities/helpers/DateTimeFormatters'
import UpdateVehicle from '../UpdateVehicle/UpdateVehicle'
import ViewReport from '../ViewReport/ViewReport'
import Colors from '../../../../assets/Colors'
import { vehicleDetailsStyles } from './vehicleDetailsStyles'
import { VehicleListStyles } from '../VehicleList/vehicleListStyles'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { apacDateFormat, isApac } from '../../../../utilities/helpers/RegionHelper'

const VehicleDetails = () => {
    const navigate = useNavigate()
    const { addSnack } = useContext(SnackbarContext)
    const { currentLanguage } = useContext(LanguageContext)
    const windowSize = useRef([window.innerWidth, window.innerHeight])
    const interacted = useRef<boolean>(false)
    const selectedVehicle = JSON.parse(useLocation().state.item)
    const [openViewReport, setOpenViewReport] = useState<boolean>(false)
    const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
    const [updateName, setUpdateName] = useState<string>(selectedVehicle?.vehicleName)
    const [updateNameError, setUpdateNameError] = useState<string>('')
    const [editName, setEditName] = useState<boolean>(false)
    const [odometerEdited, setOdometerEdited] = useState<string>('')
    const [history, setHistory] = useState([])
    const [report, setReport] = useState({})
    const [loadingHistory, setLoadingHistory] = useState<boolean>(true)
    const { data: inspectionHistory } = useInspectionByVehicle(selectedVehicle.vehicleVin)

    useEffect(() => {
        if (inspectionHistory) {
            setHistory(inspectionHistory)
            setLoadingHistory(false)
        }
    }, [inspectionHistory])

    const validateFields = () => {
        let validate = true;
        if (!updateName) {
            setUpdateNameError((language as any)[currentLanguage].errorVehicle)
            validate = false
        }
        return validate;
    }

    const handleGoBack = async () => {
        setEditName(false)
        navigate(-1)
    }

    const handleUpdateName = async () => {
        if (!validateFields()) {
            return false
        }
        else {
            try {
                const vehicleRequest = {
                    make: selectedVehicle.make,
                    vehicleName: updateName,
                    vehicleVin: selectedVehicle.vehicleVin,
                    year: selectedVehicle.year,
                    model: selectedVehicle.model,
                    licensePlate: selectedVehicle.licensePlate,
                    color: selectedVehicle.color,
                    lastOdometerReading: odometerEdited || selectedVehicle.lastOdometerReading,
                    storeNumber: selectedVehicle.storeNumber,
                    inspectionFrequency: selectedVehicle.inspectionFrequency,
                    createdBy: selectedVehicle.createdBy,
                    createdDate: selectedVehicle.createdDate,
                    obsoleteRecord: selectedVehicle.obsoleteRecord,
                    lastInspectionDate: selectedVehicle.lastInspectionDate,
                    lastServiceDate: selectedVehicle.lastServiceDate
                }
                await MyFleetService.updateVehicleDetails(vehicleRequest);
                addSnack({
                    severity: 'success',
                    message: (language as any)[currentLanguage].successUpdated,
                    action: null,
                    duration: 3000
                })
                setUpdateName(updateName)
                setEditName(false)
            }
            catch (e: any) {
                addSnack({
                    severity: 'error',
                    message: e,
                    action: null,
                    duration: 3000
                })
                setEditName(false)
            }
        }
    }

    const renderBackButton = () => {
        return (
            <Grid xs={12} sx={vehicleDetailsStyles.gridDetail} item>
                <Button
                    onClick={handleGoBack}
                    style={{ padding: 0 }}
                    data-testid="go_back">
                    <ArrowBackOutlined fontSize='medium' style={{ padding: 0, color: Colors.napaGrey2 }} />
                    <Typography data-testid={'back_button'} sx={vehicleDetailsStyles.backButton}>
                    {(language as any)[currentLanguage].back}
                    </Typography>
                </Button>
            </Grid>
        )
    }

    const renderTitle = () => {
        return (
            <Grid xs={12} sx={vehicleDetailsStyles.gridDetail} marginTop={1} item>
                {editName && <>
                    <TextField
                        sx={vehicleDetailsStyles.edit}
                        inputProps={{ 'data-testid': 'vehicle_name', style: vehicleDetailsStyles.editTitle }}
                        type='text'
                        placeholder={`${(language as any)[currentLanguage].vehicleName}*`}
                        error={!!updateNameError}
                        autoFocus
                        value={updateName}
                        onChange={(vehicleName) => setUpdateName(vehicleName.target.value)}
                        onKeyUp={() => setUpdateNameError('')} />
                    <Button
                        onClick={() => handleUpdateName()}
                        style={{ alignSelf: 'center' }}
                        data-testid={'save_button'}
                    >
                        <Typography data-testid="save_name" sx={vehicleDetailsStyles.saveButton}>
                        {(language as any)[currentLanguage].save}
                        </Typography>
                    </Button></>
                }
                {!editName && <><Typography data-testid={'vehicle_name'} sx={vehicleDetailsStyles.title}>
                    {updateName || selectedVehicle?.vehicleName}
                </Typography>
                    <Button
                        onClick={() => setEditName(true)}
                        data-testid={'edit_button'}>
                        <Typography data-testid="edit_name" sx={vehicleDetailsStyles.editText}>
                        {(language as any)[currentLanguage].edit}
                        </Typography>
                    </Button></>
                }
            </Grid>
        )
    }
    const yymcText = () => {
        return `${selectedVehicle?.year} ${selectedVehicle?.make} ${selectedVehicle?.model} ${selectedVehicle?.color}`
    }

    const renderContent = () => {
        return (<>
            <Grid xs={12} sx={vehicleDetailsStyles.gridDetail} item>
                <Typography sx={[vehicleDetailsStyles.titleText, vehicleDetailsStyles.fontBold]}>
                    {(language as any)[currentLanguage].ymmcTitle}&nbsp;
                </Typography>
                <Typography sx={[vehicleDetailsStyles.titleText, vehicleDetailsStyles.fontNormal]}>
                    {yymcText()}
                </Typography>
                <Typography sx={[vehicleDetailsStyles.titleText, vehicleDetailsStyles.fontBold]} marginLeft={5}>
                {(language as any)[currentLanguage].licensePlate}&nbsp;
                </Typography>
                <Typography sx={[vehicleDetailsStyles.titleText, vehicleDetailsStyles.fontNormal]}>
                    {selectedVehicle?.licensePlate}
                </Typography>
                <Typography sx={[vehicleDetailsStyles.titleText, vehicleDetailsStyles.fontBold]} marginLeft={5}>
                {(language as any)[currentLanguage].vinText}&nbsp;
                </Typography>
                <Typography sx={[vehicleDetailsStyles.titleText, vehicleDetailsStyles.fontNormal]}>
                    {selectedVehicle?.vehicleVin}
                </Typography>
            </Grid>
            <Grid xs={12} sx={vehicleDetailsStyles.gridDetail} marginTop={1} item>
                <Grid xs={6} item>
                    <Typography sx={vehicleDetailsStyles.bodyTitle}>
                    {(language as any)[currentLanguage].lastInspection}
                    </Typography>
                    <Typography sx={vehicleDetailsStyles.lastInspection}>
                        {selectedVehicle?.lastInspectionDate ? isApac ? apacDateFormat(selectedVehicle?.lastInspectionDate) : getStringToDate(selectedVehicle?.lastInspectionDate) : (language as any)[currentLanguage].noInspectionCompleted}
                    </Typography>
                    {selectedVehicle?.lastInspectionDate && <Typography sx={vehicleDetailsStyles.bodyText}>
                    {(language as any)[currentLanguage].byText} {selectedVehicle.createdBy}
                    </Typography>}
                </Grid>
                <Grid xs={6} item>
                    <Typography sx={vehicleDetailsStyles.bodyTitle}>
                    {(language as any)[currentLanguage].odometer}
                    </Typography>
                    <Button
                        onClick={() => setOpenUpdateModal(true)}
                        style={{ padding: 0 }}
                        data-testid={'update_odometer'}
                    >
                        <Typography sx={vehicleDetailsStyles.updatedOdometer}>
                            {odometerEdited || selectedVehicle?.lastOdometerReading}
                        </Typography>
                        <EditOutlined fontSize='small' style={{ padding: 0, color: Colors.napaBlueLink }} />
                    </Button>
                </Grid>
            </Grid>
            <Grid xs={6} sx={vehicleDetailsStyles.gridDetail} marginTop={1} item>
                <Typography sx={vehicleDetailsStyles.reportHeader}>
                {(language as any)[currentLanguage].inspectionReport}
                </Typography>
            </Grid>
        </>)
    }


    const VehicleDetailsGrid: ReactElement = useMemo(() => {
        const QuickSearchToolbar = () => {
            const [searchFilter, setSearchFilter] = useState<string>('')

            return (
                <Box sx={{ p: 0.5, pb: 0 }}>
                    <Box style={VehicleListStyles.searchBarBox}>
                        <GridToolbarQuickFilter
                            sx={VehicleListStyles.searchBar}
                            placeholder={(language as any)[currentLanguage].searchInspection}
                            quickFilterParser={(searchInput: string) => {
                                setSearchFilter(searchInput)
                                interacted.current = true
                                return searchInput
                                    .split('/')
                                    .map((value) => value.trim())
                                    .filter((value) => value !== '')
                            }}
                        />
                    </Box>
                    <Divider />
                    {searchFilter ? (
                        <Box style={VehicleListStyles.searchResultsBox}>
                            <Typography style={VehicleListStyles.searchResultsFont}>
                            {(language as any)[currentLanguage].result} "{searchFilter}"</Typography>
                        </Box>
                    ) : null
                    }
                </Box>
            )
        }

        const notesFlag = (notes: any) => {
            let redExist = false;
            let yellowExist = false;

            notes.forEach((item: any) => {
                if (item.questions) {
                    item.questions.forEach((question: any) => {
                        if (question.color === "red")
                            redExist = true;
                        if (question.color === "yellow")
                            yellowExist = true;
                    })
                }
            })
            return (
                <>
                    {redExist ? <WarningAmberOutlined fontSize='small' style={{ color: Colors.napaRed }}></WarningAmberOutlined>
                        : yellowExist ? <WarningAmberOutlined fontSize='small' style={{ color: Colors.napaYellow3 }}></WarningAmberOutlined>
                            : <CheckCircleOutline fontSize='small' style={{ color: Colors.napaGreenDark }}></CheckCircleOutline>}
                    <Typography>
                        &nbsp;&nbsp;{redExist ? (language as any)[currentLanguage].redMessage : yellowExist ? (language as any)[currentLanguage].yellowMessage : ''}
                    </Typography>
                </>
            )
        }

        const handleViewReport = (report: any) => {
            setReport(report)
            setOpenViewReport(true)
        }

        const columns = [
            {
                field: 'inspectionDate',
                headerName: (language as any)[currentLanguage].dateText,
                minWidth: 200,
                editable: false,
                valueFormatter: (params: any) => {
                    return isApac? apacDateFormat(params.value) : getStringToDate(params.value)
                },
            },
            {
                field: 'inspectedBy',
                headerName: (language as any)[currentLanguage].completedBy,
                minWidth: 250,
                editable: false,
                filterable: false,
            },
            {
                field: 'odometerReading',
                headerName: (language as any)[currentLanguage].odometer.toUpperCase(),
                minWidth: 200,
                editable: false,
                filterable: false,
            },
            {
                field: 'notes',
                headerName: (language as any)[currentLanguage].notes.toUpperCase(),
                minWidth: 500,
                editable: false,
                renderCell: (params: any) => {
                    return (
                        <>{notesFlag(params.row.notes)}</>
                    )
                }
            },
            {
                field: 'report',
                headerName: '',
                minWidth: 100,
                editable: false,
                filterable: false,
                renderCell: (params: any) => {
                    return (
                        <Button
                            onClick={(event) => {
                                event.stopPropagation();
                                handleViewReport(params.row)
                            }}
                            sx={vehicleDetailsStyles.viewReport}
                            data-testid="view_report">
                            {(language as any)[currentLanguage].viewReport}
                        </Button>
                    )
                }
            }
        ]

        const renderDataGrid = () => {
            return (
                <DataGrid
                    getRowId={(row) => `${row.inspectionDate}${row.index}`}
                    disableColumnMenu
                    style={{ height: `${windowSize.current[1] - 250}px` }}
                    components={{
                        Toolbar: QuickSearchToolbar,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                {(language as any)[currentLanguage].noInspectionCompleted}
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              {(language as any)[currentLanguage].noResults}
                            </Stack>
                        )
                    }}
                    disableColumnSelector={true}
                    loading={loadingHistory}
                    rows={history?.map((list: any, index: number) => {
                        const row = {
                            inspectionDate: list.inspectionDate,
                            inspectedBy: list.inspectedBy,
                            notes: list.completedVehicleInspectionForm.questionnaires,
                            odometerReading: list.completedVehicleInspectionForm.odometerReading,
                            report: '',
                            index: index,
                            vehicleVin: list.vehicleVin,
                            storeNumber: list.storeNumber
                        }
                        return row
                    }) || []}
                    columns={columns}
                    hideFooterSelectedRowCount={true}
                    disableVirtualization={process.env.NODE_ENV === 'test'}
                    sx={VehicleListStyles.gridSize}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 }
                        }
                    }}
                />
            )
        }
        return renderDataGrid()
    }, [history, loadingHistory, currentLanguage])

    return (
        <Grid container spacing={1} sx={vehicleDetailsStyles.gridContainer}>
            {renderBackButton()}
            {renderTitle()}
            {renderContent()}
            <Grid item container xs={12}>
                {VehicleDetailsGrid}
            </Grid>
            {openViewReport && <ViewReport
                openViewReport={openViewReport}
                setOpenViewReport={setOpenViewReport}
                setReport={setReport}
                report={report}
                vehicleDetails={selectedVehicle}
                updateName={updateName}
            />}
            {<UpdateVehicle
                vehicleDetails={selectedVehicle}
                openUpdateModal={openUpdateModal}
                setOpenUpdateModal={setOpenUpdateModal}
                setOdometerEdited={setOdometerEdited}
                updateName={updateName}
            />}
        </Grid>
    )
}

export default VehicleDetails