import axios from 'axios'

export function getApacToken(type: string = '') {
  function Token(name: string) {
    return localStorage.getItem(name)?.slice(1, -1)
  }

  switch (type) {
    case 'idToken':
      return Token('ROCP_idToken')
    case 'refreshToken':
      return Token('ROCP_refreshToken')
    default:
      return Token('ROCP_token')
  }
}

export interface UserDataTypes {
  givenName: string
  familyName: string
  username: string
  email: string
  defaultStoreNumber: string
  phoneNumber: string
  userType: string
  storeList: string[]
  enabled: boolean
  createdDate: string
}

export interface pingUserDataTypes {
  given_name: string
  family_name: string
  username: string
  groups: string[]
  phone_number: string
  UserPrincipalName: string
  name: string
}

export async function getApacUser(token: string): Promise<pingUserDataTypes> {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PING_URL}/idp/userinfo.openid`,
      config
    )
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getApacUserByUserName(userName: string): Promise<UserDataTypes> {
  const token = getApacToken()
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DELIVERYAPI_URL}/users/v2/username/${userName}`,
      config
    )
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return Promise.reject(e)
  }
}
