import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Clear, FlagOutlined } from '@mui/icons-material'
import { MyFleetService, Vehicles } from '../../../../utilities/services/MyFleetService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { getStringToDate } from '../../../../utilities/helpers/DateTimeFormatters'
import { VehicleListStyles } from './vehicleListStyles'
import { MyFleetStyles } from '../../myFleetStyles'
import Colors from '../../../../assets/Colors'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { apacDateFormat, isApac } from '../../../../utilities/helpers/RegionHelper'
import dayjs from 'dayjs'

const VehicleList = ({ setOpenVehicleDetails, allVehicles, openVehicleDetails }: any) => {
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  let navigate = useNavigate()
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const interacted = useRef<boolean>(false)
  const [vehicleList, setVehicleList] = useState<Vehicles[]>([])
  const [openDisableDialog, setOpenDisableDialog] = useState<boolean>(false)
  const [vehicleVin, setVehicleVin] = useState<string>('')

  useEffect(() => {
    if (allVehicles) {
      const filteredVehicles = allVehicles?.filter((item: any) => item.obsoleteRecord === false)
      setVehicleList(filteredVehicles)
    }
  }, [allVehicles])

  const handleDisable = async () => {
    try {
      await MyFleetService.disableVehicle(vehicleVin)
      addSnack({
        severity: 'success',
        message: (language as any)[currentLanguage].successDisabled,
        action: null,
        duration: 3000
      })
      setOpenDisableDialog(false)
      setVehicleVin('')
    } catch (e: any) {
      addSnack({
        severity: 'error',
        message: e,
        action: null,
        duration: 3000
      })
    }
  }

  const HandleDialogMenu = () => {
    return (
      <Dialog
        open={openDisableDialog}
        onClose={() => setOpenDisableDialog(false)}
        data-testid="dialog-box">
        <DialogTitle sx={MyFleetStyles.titleWrapper}>
          <Typography sx={MyFleetStyles.dialogTitle}>{(language as any)[currentLanguage].disableVehicle}</Typography>
          <IconButton onClick={() => setOpenDisableDialog(false)}>
            <Clear fontSize="medium" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={MyFleetStyles.dialogContent}>
          <DialogContentText>{(language as any)[currentLanguage].confirmDisable}</DialogContentText>
        </DialogContent>
        <DialogActions sx={MyFleetStyles.dialogAction}>
          <React.Fragment>
            <Button
              variant="secondary"
              sx={MyFleetStyles.dialogButtons}
              onClick={() => setOpenDisableDialog(false)}>
              {(language as any)[currentLanguage].cancel}
            </Button>
            <Button
              variant="primary"
              sx={MyFleetStyles.dialogButtons}
              onClick={() => handleDisable()}
              data-testid="confirm_disable">
              {(language as any)[currentLanguage].confirm}
            </Button>
          </React.Fragment>
        </DialogActions>
      </Dialog>
    )
  }

  const VehicleListDataGrid: ReactElement = useMemo(() => {
    const lastInspectionDayCount = (startDate: any) => {
      if (isApac) {
        if (!startDate) return false
        const date1 = dayjs(startDate)
        const date2 = dayjs()
        const differenceInDays = date2.diff(date1, 'day')
        return !(differenceInDays >= 7)
      }
      let date1 = new Date(startDate).toLocaleDateString()
      let date2 = new Date().toLocaleDateString()
      return date1 === date2
    }

    const lastInspectionFlag = (params: any) => {
      return (
        !lastInspectionDayCount(params.row.lastInspectionDate) && (
          <Tooltip title={(language as any)[currentLanguage].inspectionRequired} arrow placement="top">
            <FlagOutlined fontSize="small" style={{ color: Colors.napaRed }}></FlagOutlined>
          </Tooltip>
        )
      )
    }

    const redirectToReport = (item: any) => {
      setOpenVehicleDetails(true)
      navigate(`/VehicleDetails`, { state: { item: JSON.stringify(item.row) } })
    }

    const QuickSearchToolbar = () => {
      const [searchFilter, setSearchFilter] = useState<string>('')

      return (
        <Box sx={{ p: 0.5, pb: 0 }}>
          <Box style={VehicleListStyles.searchBarBox}>
            <GridToolbarQuickFilter
              sx={VehicleListStyles.searchBar}
              placeholder={(language as any)[currentLanguage].searchVehicles}
              quickFilterParser={(searchInput: string) => {
                setSearchFilter(searchInput)
                interacted.current = true
                return searchInput
                  .split('/')
                  .map((value) => value.trim())
                  .filter((value) => value !== '')
              }}
            />
          </Box>
          <Divider />
          {searchFilter ? (
            <Box style={VehicleListStyles.searchResultsBox}>
              <Typography style={VehicleListStyles.searchResultsFont}>
              {(language as any)[currentLanguage].result} "{searchFilter}"
              </Typography>
            </Box>
          ) : null}
        </Box>
      )
    }

    const columns = [
      {
        field: 'vehicleName',
        headerName: (language as any)[currentLanguage].vehicleName.toUpperCase(),
        minWidth: 200,
        editable: false
      },
      {
        field: 'ymmc',
        headerName: (language as any)[currentLanguage].ymmcHeader,
        minWidth: 250,
        editable: false,
        filterable: false
      },
      {
        field: 'licensePlate',
        headerName: (language as any)[currentLanguage].licensePlate.toUpperCase(),
        minWidth: 200,
        editable: false,
        filterable: false
      },
      {
        field: 'lastInspectionDate',
        headerName: (language as any)[currentLanguage].lastInspection.toUpperCase(),
        minWidth: 350,
        editable: false,
        renderCell: (params: any) => {
          return (
            <>
              {lastInspectionFlag(params)}&nbsp;&nbsp;
              <Typography>
              {params.row?.lastInspectionDate ? isApac ? apacDateFormat(params.row?.lastInspectionDate) : getStringToDate(params.row?.lastInspectionDate) : (language as any)[currentLanguage].noInspectionCompleted}
              </Typography>
            </>
          )
        }
      },
      {
        field: 'lastOdometerReading',
        headerName: (language as any)[currentLanguage].odometer.toUpperCase(),
        minWidth: 200,
        editable: false,
        filterable: false
      },
      {
        field: 'obsoleteRecord',
        headerName: '',
        minWidth: 150,
        editable: false,
        filterable: false,
        renderCell: (params: any) => {
          return (
            <Button
              onClick={async (event) => {
                event.stopPropagation()
                setVehicleVin(params.row.vehicleVin)
                setOpenDisableDialog(true)
              }}
              sx={VehicleListStyles.disableText}
              data-testid="disable_vehicle">
              {(language as any)[currentLanguage].disable}
            </Button>
          )
        }
      }
    ]

    const renderDataGrid = () => {
      return (
        <DataGrid
          getRowId={(row) => row.vehicleVin}
          disableColumnMenu
          style={{ height: `${windowSize.current[1] - 250}px` }}
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {(language as any)[currentLanguage].noVehicles}
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {(language as any)[currentLanguage].noResults}
              </Stack>
            )
          }}
          disableColumnSelector={true}
          rows={
            vehicleList?.map((list) => {
              const row = {
                model: list.model,
                ymmc: `${list.year} ${list.make} ${list.model} ${list.color}`,
                licensePlate: list.licensePlate,
                lastInspectionDate: list.lastInspectionDate,
                lastOdometerReading: list.lastOdometerReading,
                disable: '',
                obsoleteRecord: list.obsoleteRecord,
                vehicleVin: list.vehicleVin,
                make: list.make,
                vehicleName: list.vehicleName,
                year: list.year,
                color: list.color,
                storeNumber: list.storeNumber,
                inspectionFrequency: list.inspectionFrequency,
                createdBy: list.createdBy,
                createdDate: list.createdDate,
                lastServiceDate: list.lastServiceDate
              }
              return row
            }) || []
          }
          columns={columns}
          hideFooterSelectedRowCount={true}
          disableVirtualization={process.env.NODE_ENV === 'test'}
          sx={VehicleListStyles.gridSize}
          componentsProps={{
            toolbar: {
              showQuickFilter: false,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
          onRowClick={(item) => {
            redirectToReport(item)
          }}
        />
      )
    }
    return renderDataGrid()
  }, [vehicleList, setOpenDisableDialog, navigate, setOpenVehicleDetails, currentLanguage])

  return (
    <Grid item container>
      <Grid item container xs={12}>
        {!openVehicleDetails && VehicleListDataGrid}
      </Grid>
      {HandleDialogMenu()}
    </Grid>
  )
}

export default VehicleList
