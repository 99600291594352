import { Box, Button, Typography } from "@mui/material"
import { AddressInfo, CustomerInfo, formatLatLang } from "../../../../utilities/services/CustomerService/CustomerService"
import { customerDetailsStyles } from "../CustomerDetailsStyles"
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import { ReactComponent as DropOffPin } from '../../../../assets/drop_off_pin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { LanguageContext } from "../../../../utilities/contexts/LanguageContext"
import { useContext } from "react"
import language from "../../../../language/language"

interface InputProps {
    addressInfo: AddressInfo,
    onEdit: () => void,
    customerInfo: CustomerInfo,
    index: number
}

const CustomerAddressView = ({addressInfo, onEdit, customerInfo, index}: InputProps) => {
    const { currentLanguage } = useContext(LanguageContext)

    return <Box sx={{ textAlign: 'left' }} display="flex" flexDirection={"column"} gap={"16px"}>
    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
        <Typography sx={customerDetailsStyles.typoHeader5}>
            
            {(language as any)[currentLanguage].location} {index + 1}: {customerInfo?.name} - {index + 1}
        </Typography>
        <Button variant="text" sx={customerDetailsStyles.paragraph2Link} onClick={onEdit}>Edit</Button>
    </Box>
    <Box>
        <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
            <PinIcon />
            {(language as any)[currentLanguage].address}
        </Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{addressInfo?.addressline1}</Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{addressInfo?.city}, {addressInfo?.state} {addressInfo?.zip}</Typography>
    </Box>
    <Box>
        <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
            <DropOffPin />
            {(language as any)[currentLanguage].dropOffSpot}
        </Typography>
        {addressInfo?.deliveryGeocodes?.[0]?.isDefault === 'Y'
            ? <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>
                {(language as any)[currentLanguage].backOfStore}
            </Typography>
            : <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{addressInfo?.deliveryGeocodes?.[0]?.pinLabel}</Typography>}
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{formatLatLang(+addressInfo?.deliveryGeocodes?.[0]?.latitude, +addressInfo?.deliveryGeocodes?.[0]?.longitude)}</Typography>
    </Box>
    <Box>
        <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
            <NotesIcon />
            {(language as any)[currentLanguage].deliveryNotes}
        </Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>
            {addressInfo?.note ? addressInfo?.note : 'None'}
        </Typography>
    </Box>
</Box>
}

export default CustomerAddressView