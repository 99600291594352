import React, { ReactElement, useContext } from 'react'

import { Button, Grid } from '@mui/material'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import language from '../../../../language/language'

import { dateFilterStyles } from './DateFilterStyles'
import Colors from '../../../../assets/Colors'
import DatePickerAsNumber from './DatePickerAsNumber'
import { makeEndDate, makeStartDate } from './Dates'

type DateFilterProps = {
  startDate: number
  setStartDateFilter: (date: number) => void
  endDate: number
  setEndDateFilter: (date: number) => void
  onSearch: () => void
  resetAll: () => void
}

const DateFilter = ({
  startDate,
  setStartDateFilter,
  endDate,
  setEndDateFilter,
  onSearch,
  resetAll,
}: DateFilterProps): ReactElement => {
  const { currentLanguage } = useContext(LanguageContext)

  return (
    <>
      <Grid item container sx={{ display: 'flex', justifyContent: 'end' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePickerAsNumber
            label={(language as any)[currentLanguage].startDate}
            value={startDate}
            onChange={(date: number) => {
              const startDate = makeStartDate(date)
              setStartDateFilter(startDate)
            }}
          />
          <p
            style={{
              paddingLeft: 10,
              paddingRight: 20,
              paddingTop: 5,
              fontSize: 16,
              fontWeight: '400'
            }}>
            {' '}
            {(language as any)[currentLanguage].toText}
          </p>
          <DatePickerAsNumber
            label={(language as any)[currentLanguage].endDate}
            value={endDate}
            onChange={(date: number) => {
              const endDate = makeEndDate(date)
              setEndDateFilter(endDate)
            }}
          />
        </LocalizationProvider>
        <Button
          data-testid="search-button"
          size="small"
          style={{
            display: 'flex',
            justifyContent: 'start',
            color: Colors.napaBlueLink,
            top: '6px'
          }}
          onClick={onSearch}>
          {(language as any)[currentLanguage].search}
        </Button>
        <Button data-testid="reset-all-button" size="small" style={dateFilterStyles.resetButtonStyle} onClick={resetAll}>
          {(language as any)[currentLanguage].reset}
        </Button>
      </Grid>
    </>
  )
}

export default DateFilter
