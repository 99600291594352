import React, { ReactElement } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { datePickerAsNumberStyles } from './DatePickerAsNumberStyles'
import { isApac } from '../../../../utilities/helpers/RegionHelper'

type DateFilterProps = {
  label: string
  value: number
  onChange: (date: number) => void
}

const DatePickerAsNumber = ({
  label,
  value,
  onChange,
}: DateFilterProps): ReactElement => {

  const currentDate = Date.now()
  const yearInMilli = 31556952000
  return (
    <div style={datePickerAsNumberStyles.datePickerStyle}>
              <DatePicker
                  label={label}
                  minDate={new Date(currentDate - yearInMilli)}
                  maxDate={new Date(currentDate + yearInMilli)}
                  format={isApac ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                  value={new Date(value)}
                  onChange={(date: Date | null) =>
                      onChange(date ? date.getTime() : new Date().getTime())
                  }
                  slotProps={{ textField: { size: 'small' } }}
              />
    </div>
  )
}

export default DatePickerAsNumber
