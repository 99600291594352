import { Language } from './LanguageTypes'
import { englishLanguageValues } from './EnglishLanguageValues'
import { frenchLanguageValues } from './FrenchLanguageValues'
import { englishLanguageValuesApac } from './EnglishLanguageValuesApac'
import { isApac } from '../utilities/helpers/RegionHelper'
import { frenchLanguageValuesApac } from './FrenchLanguageValuesApac'

const language: Language = {
  en: isApac ? englishLanguageValuesApac  : englishLanguageValues,
  fr: isApac ? frenchLanguageValuesApac : frenchLanguageValues
}
export default language
