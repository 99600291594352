import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  Modal,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { useContext, useEffect, useState } from 'react'
import { UserManagementService } from '../../../../utilities/services/UserManagementService'
import TextField from '@mui/material/TextField'
import { AuthContext } from '../../../../utilities/contexts/AuthContext'
import addDriverStyles from './addDriverStyles'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ImportUsers from '../ImportUsers/ImportUsers'
import { useUsersByStore } from '../../../../queries/Users/useUsersByStore'
import MuiPhoneNumber from 'material-ui-phone-number'
import Tooltip from '@mui/material/Tooltip'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { isApac } from '../../../../utilities/helpers/RegionHelper'

type AddDriverProps = {
  openModal: boolean
  setOpenModal: (openModal: boolean) => void
  openImportModal: boolean
  setOpenImportModal: (openImportModal: boolean) => void
}

const AddDriver = ({
  openModal,
  setOpenModal,
  openImportModal,
  setOpenImportModal
}: AddDriverProps) => {
  const { addSnack } = useContext(SnackbarContext)
  const { currentUser } = useContext(AuthContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { data: storeUsers, refetch: refetchUsers } = useUsersByStore()
  const [givenName, setGivenName] = useState<string>('')
  const [familyName, setFamilyName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [userType, setUserType] = useState<string>('')
  const [userTypeErrorMessage, setUserTypeErrorMessage] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [givenNameErrorMessage, setGivenNameErrorMessage] = useState<string>('')
  const [familyNameErrorMessage, setFamilyNameErrorMessage] = useState<string>('')
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
  const [usernameErrorMessage, setUsernameErrorMessage] = useState<string>('')
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string>('')
  const [defaultStoreErrorMessage, setDefaultStoreErrorMessage] = useState<string>('')
  const [storeOptions, setStoreOptions] = useState<string[]>([])
  const [storeGroups, setStoreGroups] = useState<string[]>([])
  const [defaultStore, setDefaultStore] = useState('')
  const userIsOwnerOrManager =  (currentUser?.userType === 'district_manager' || currentUser?.userType === 'owner')
  const adminMenuOptions=[
    {value:'admin', label:(language as any)[currentLanguage].admin},
    {value:'driver', label:(language as any)[currentLanguage].driver}
  ]
  const managerMenuOptions=[
    {value:'owner', label:(language as any)[currentLanguage].owner},
    {value:'district_manager', label:(language as any)[currentLanguage].districtManager}
  ]

  useEffect(() => {
    if (currentUser) {
      setStoreOptions(currentUser.storeList)
    }
  }, [currentUser])

  const handleClose = () => {
    setGivenName('')
    setFamilyName('')
    setEmail('')
    setUsername('')
    setPhoneNumber('')
    setDefaultStore('')
    setUserType('')
    setStoreGroups([])
    clearErrorMessages()
    refetchUsers()
    setOpenModal(false)
  }

  const clearErrorMessages = () => {
    setGivenNameErrorMessage('')
    setFamilyNameErrorMessage('')
    setEmailErrorMessage('')
    setUsernameErrorMessage('')
    setPhoneNumberErrorMessage('')
    setUserTypeErrorMessage('')
    setDefaultStoreErrorMessage('')
  }

  const handleEmailChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setEmail(e.target.value)
  }

  const isUserNameExist = (value:any): boolean => {
    const username = value
    return storeUsers?.some((user) => user.username === username) || false
  }

  const handleUsername = (e: { target: { value: React.SetStateAction<string> } }) => {
    const {value} = e.target
    if(isApac){
      if(value.length > 6) {
        return false
      } else{
        setUsername(value);
        (value.length === 6 && isUserNameExist(value)) ? setUsernameErrorMessage((language as any)[currentLanguage].userExist) : setUsernameErrorMessage('')
      }
   } else{
      setUsername(value)
   }
  }

  const validateEmail = (): boolean => {
    const emailRegex =
      /* eslint-disable */
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
      if(isApac){
        return email ? emailRegex.test(email.toLowerCase()) : true
      }
    return emailRegex.test(email.toLowerCase())
  }

  const validateUsername = (): boolean => {
    if(isApac){
      if(!username || username.length < 6){
        return false
      } 
    } else{
      const usernameRegex = /^[A-Za-z0-9_@.!#]*$/
      if (usernameRegex) {
        return true
      }
      if (username.length <= 6 && username.length >= 14) {
        return false
      }
    } 
    return true
  }

  const validatePhone = (): boolean => {
    if(isApac){
      return (phoneNumber && phoneNumber.length > 3) ? phoneNumber.length === 12 : true
    }
    if (!phoneNumber || phoneNumber === '+1' || phoneNumber === '+') {
      return true
    }
    if (phoneNumber.length !== 12) {
      return false
    }
    return true
  }

  const validateAddDriverFields = () => {
    let validate = true
    if (!givenName) {
      setGivenNameErrorMessage((language as any)[currentLanguage].fName)
      validate = false
    }
    if (!familyName) {
      setFamilyNameErrorMessage((language as any)[currentLanguage].lName)
      validate = false
    }
    if (!validateEmail()) {
      setEmailErrorMessage((language as any)[currentLanguage].validEmail)
      validate = false
    }
    if (!validateUsername()) {
      setUsernameErrorMessage((language as any)[currentLanguage].validUser)
      validate = false
    }
    if (!validatePhone()) {
      setPhoneNumberErrorMessage((language as any)[currentLanguage].invalidPhone)
      validate = false
    }
    if (!defaultStore) {
      setDefaultStoreErrorMessage((language as any)[currentLanguage].selectDefaultStore)
      validate = false
    }
    if (!userType) {
      setUserTypeErrorMessage((language as any)[currentLanguage].userType)
      validate = false
    }
    return validate
  }

  const addNewDriver = async () => {
    clearErrorMessages()
    if (validateAddDriverFields()) {
      try {
        await UserManagementService.addUser({
          email: email,
          familyName: familyName,
          givenName: givenName,
          username: username,
          //@ts-ignore
          phoneNumber: phoneNumber.length === 12 ? phoneNumber : null,
          defaultStoreNumber: defaultStore,
          storeList: storeGroups,
          userType: userType
        })
        addSnack({
          severity: 'success',
          message: isApac ? 'Success!' : `${(language as any)[currentLanguage].emailFirst} ${email} ${(language as any)[currentLanguage].emailSecond}`,
          action: null,
          duration: 3000
        })
        handleClose()
      } catch (e: any) {
        if (e.response.data.message.includes('User account already exists')) {
          setUsernameErrorMessage((language as any)[currentLanguage].userExist)
        }
        console.error((language as any)[currentLanguage].errorDriver, e.message)
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorDriver,
          action: null,
          duration: 3000
        })
      }
    }
  }

  const DefaultStoreSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          onChange={(e) => setDefaultStore(e.target.value)}
          value={defaultStore}
          inputProps={{ 'data-testid': 'default-store-select' }}
          error={!!defaultStoreErrorMessage}
          displayEmpty
          renderValue={(selected) =>
            selected ? <em>{selected}</em> : <em>{(language as any)[currentLanguage].defaultStore}</em>
          }>
          {storeOptions
            ? storeOptions.sort().map((store) => (
                <MenuItem key={store} value={store}>
                  <ListItemText primary={`#${store}`} />
                </MenuItem>
              ))
            : null}
        </Select>
        <Typography sx={addDriverStyles.errorText}>{defaultStoreErrorMessage}</Typography>
      </FormControl>
    )
  }

  const handleStoreChange = (event: SelectChangeEvent<typeof storeGroups>) => {
    const {
      target: { value }
    } = event
    if (value[value.length - 1] === 'all') {
      setStoreGroups(storeGroups.length === storeOptions.length ? [] : storeOptions)
      return
    }
    setStoreGroups(typeof value === 'string' ? value.split(',') : value)
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8
      }
    }
  }
  const StoreSelect = () => {
    const isAllSelected = storeOptions.length > 0 && storeGroups.length === storeOptions.length
    return (
      <Box>
        <FormControl>
          <Typography sx={addDriverStyles.addDriverLabel}>{(language as any)[currentLanguage].assignedStore}</Typography>
          <Select
            sx={{ width: '363px', maxHeight: '200px', overflowY: 'scroll' }}
            labelId="store-select-dropdown-checkbox"
            id="store-select"
            multiple
            displayEmpty
            value={storeGroups}
            onChange={handleStoreChange}
            MenuProps={MenuProps}
            renderValue={(selected) =>
              selected.length === 0 ? (
                <em>{(language as any)[currentLanguage].allStores}</em>
              ) : (
                `${selected.filter((stores) => stores !== defaultStore).join(', ')}`
              )
            }>
            <MenuItem value="all">
              <Checkbox
                checked={isAllSelected}
                indeterminate={storeGroups.length > 0 && storeGroups.length < storeOptions.length}
              />
              <ListItemText primary={(language as any)[currentLanguage].selectAll} />
            </MenuItem>
            {storeOptions
              ?.filter((options) => options !== defaultStore)
              .map((store) => (
                <MenuItem key={store} value={store}>
                  <Checkbox checked={storeGroups.indexOf(store) > -1} />
                  <ListItemText primary={`#${store}`} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string)
  }

  const UserTypeDropdown = () => {
    return (
      <Box>
        <FormControl fullWidth>
          <Typography sx={addDriverStyles.addDriverLabel}>{(language as any)[currentLanguage].userTypeTitle}*</Typography>
          <Select
            id="user-type-select"
            inputProps={{ 'data-testid': 'user-type-select' }}
            value={userType}
            displayEmpty
            required={true}
            renderValue={(selected) =>
              selected.length === 0 ? (
                <em>{(language as any)[currentLanguage].selectType}</em>
              ) : (
                `${userType[0].toUpperCase() + userType.slice(1)}`
              )
            }
            placeholder={(language as any)[currentLanguage].selectType}
            onChange={handleUserTypeChange}
            error={!!userTypeErrorMessage}>
              {adminMenuOptions.map((option)=>(
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
                { userIsOwnerOrManager && 
                managerMenuOptions.map((option)=>(
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <Typography sx={addDriverStyles.errorText}>{userTypeErrorMessage}</Typography>
      </Box>
    )
  }
  const handlePhoneChange = (value: any) => {
    let validPhone = value.replace(/[{()}-]/g, '')
    validPhone = validPhone.replaceAll(' ', '')
    setPhoneNumber(validPhone)
  }
  const infoText = (language as any)[currentLanguage].infoText

  const emailText = (language as any)[currentLanguage].notUnique
  return (
    <Grid>
      <ImportUsers isOpen={openImportModal} setIsOpen={setOpenImportModal} />
      <Modal
        sx={addDriverStyles.addDriverModal}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="add-driver-modal">
        <Grid container sx={addDriverStyles.addDriverModalContainer}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={addDriverStyles.modalTitle}>{(language as any)[currentLanguage].newDriver}</Typography>
            <IconButton onClick={handleClose}>
              <ClearIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Box sx={addDriverStyles.inputContainer}>
            <Typography sx={addDriverStyles.addDriverLabel}>{(language as any)[currentLanguage].nameText}*</Typography>
            <TextField
              sx={addDriverStyles.addDriverTextField}
              inputProps={{ 'data-testid': 'firstname' }}
              type="text"
              label={`${(language as any)[currentLanguage].firstName}*`}
              error={!!givenNameErrorMessage}
              helperText={givenNameErrorMessage}
              value={givenName}
              onChange={(firstName) => setGivenName(firstName.target.value)}
            />
          </Box>
          <Box>
            <TextField
              sx={addDriverStyles.addDriverTextField}
              inputProps={{ 'data-testid': 'lastname' }}
              type="text"
              label={`${(language as any)[currentLanguage].lastName}*`}
              error={!!familyNameErrorMessage}
              helperText={familyNameErrorMessage}
              value={familyName}
              onChange={(lastName) => setFamilyName(lastName.target.value)}
            />
          </Box>
          <Box sx={addDriverStyles.inputContainer}>
            <Grid sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Typography sx={addDriverStyles.addDriverLabel}>{(language as any)[currentLanguage].email}{!isApac && '*'}</Typography>
              <Tooltip title={emailText} arrow>
                <IconButton sx={{ marginTop: -1 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <TextField
              sx={addDriverStyles.addDriverTextField}
              inputProps={{ 'data-testid': 'emailaddress', type: 'email' }}
              label={`${(language as any)[currentLanguage].email}${isApac ? '' : '*'}`}
              error={!!emailErrorMessage}
              helperText={emailErrorMessage}
              value={email}
              onChange={handleEmailChange}
            />
          </Box>
          <Box sx={addDriverStyles.inputContainer}>
            <Grid sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Typography sx={addDriverStyles.addDriverLabel}>{(language as any)[currentLanguage].usernameText}*</Typography>
              <Tooltip title={infoText} arrow>
                <IconButton sx={{ marginTop: -1 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <TextField
              sx={addDriverStyles.addDriverTextField}
              inputProps={{ 'data-testid': 'username', type: isApac? 'number' : 'text' }}
              label={`${(language as any)[currentLanguage].usernameText}*`}
              error={!!usernameErrorMessage}
              helperText={usernameErrorMessage}
              value={username}
              onChange={handleUsername}
            />
          </Box>
          <Box sx={addDriverStyles.inputContainer}>
            <Typography sx={addDriverStyles.addDriverLabel}>{(language as any)[currentLanguage].phoneNumber}</Typography>
            <MuiPhoneNumber
              defaultCountry={isApac ? 'au' : 'us'}
              onChange={handlePhoneChange}
              inputProps={{ 'data-testid': 'phonenumber' }}
              onlyCountries={isApac ? ['au'] : ['us', 'ca']}
              error={!!phoneNumberErrorMessage}
              type={'text'}
              countryCodeEditable={false}
              disableAreaCodes
              InputProps={{ disableUnderline: true }}
              value={phoneNumber}
              sx={{
                ...addDriverStyles.phoneNumberField,
                borderColor: phoneNumberErrorMessage ? '#D32F2F' : '#bfbfbf'
              }}
            />
            <Typography style={addDriverStyles.errorText}>{phoneNumberErrorMessage}</Typography>
          </Box>
          <Box sx={{ marginBottom: '17px' }}>
            <UserTypeDropdown />
            <Box sx={{ paddingY: '15px' }}>
              <Typography sx={addDriverStyles.addDriverLabel}>{(language as any)[currentLanguage].store}*</Typography>
              <DefaultStoreSelect />
            </Box>
            <StoreSelect />
          </Box>
          <Button sx={{ width: '45%', marginX: '9px' }} variant={'secondary'} onClick={handleClose}>
          {(language as any)[currentLanguage].cancel}
          </Button>
          <Button sx={{ width: '45%', marginX: '9px' }} variant={'primary'} onClick={addNewDriver}>
          {(language as any)[currentLanguage].save}
          </Button>
        </Grid>
      </Modal>
    </Grid>
  )
}

export default AddDriver
